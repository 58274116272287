import * as THREE from 'three';

const loader = new THREE.TextureLoader();

THREE.Cache.enabled = true;
function loadTexture(url) {
  return new Promise((resolve, reject) => {
    const onLoad = iTexture => resolve(iTexture);
    loader.load(url, onLoad, undefined, reject);
  });
}

export default loader;
export { loadTexture };
