import anime from 'animejs/lib/anime.es';

const duration = 200;
const bounceDelta = (1 / 180) * Math.PI;
let lock = false;
const clip = cameraControls => {
  if (lock) return;
  lock = true;

  const srcFov = cameraControls.fov;
  const dstFov = cameraControls.fov - bounceDelta;

  // 控anime.js開始動畫
  const timeline = anime.timeline({
    autoplay: false,
    duration,
  });
  timeline.add(
    {
      targets: cameraControls,
      fov: [srcFov, dstFov],
      easing: function ease() {
        return t => {
          return Math.sin(t * Math.PI);
        };
      },
    },
    0
  );

  timeline.play();

  timeline.finished.then(() => {
    const camControl = cameraControls;
    camControl.fov = srcFov;
    lock = false;
  });
};

export default clip;
