import React from 'react';
import StoreGetters from 'store/store-getters';
import FloorPlan2D from 'services/3dviewer/floorplan';
import './css/floorplan-canvas.scss';

export default class FloorPlanCanvas extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const data = StoreGetters.getCurrentRenderFloorData().data.floorplane;
    const floorplan = new FloorPlan2D(this.canvas, data, this.props.viewerCameraController);
    this.update = () => {
      floorplan.setCameraIcon();
      floorplan.updateViewPort();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.update();
    }
  }

  render() {

    return (
      <canvas
        id="FloorPlanContainer"
        className="defaultFloorPlan"
        ref={ref => {
          this.canvas = ref;
        }}
      />
    );
  }
}
