import React, { useState, useEffect, useRef } from 'react'
import firebaseApi from '../../../services/cloudApi'
import { useLocation } from 'react-router-dom';

function VerifySection() {
  const [isVerify, setIsVerify] = useState(true)
  const [status, setStatus] = useState('init') // 'init', 'verifying', 'complete', 'typing reason', 'error'
  const [reason, setReason] = useState('')
  const inputRef = useRef()

  const location = useLocation()
  const buildingId = location.pathname.substring(1, location.pathname.length)

  const getQueryString = (search, name) => {
    if (!search) {
      return null
    }
    const searchParams = new URLSearchParams(search)
    return searchParams.get(name)
  }

  const checkHqIsYc = queryString => {
    return queryString === 'yc'
  }

  useEffect(() => {
    if (checkHqIsYc(getQueryString(location.search, 'hq'))) {
      firebaseApi.fetchIsVerify(buildingId)
        .then(result => {
          if (result) {
            setIsVerify(result.ycVerify)
          } else {
            setIsVerify(false)
          }
        })
        .catch(err => console.log(err))
    }
  }, [])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  })

  const submit = async (type) => { // 'pass', 'fail'
    const body = {
      objectId: buildingId,
      status: ''
    }
    if (type === 'pass') {
      body.status = 'rendering'
    } else if (type === 'fail') {
      body.status = 'needRe-turn'
      if (reason) {
        body.reason = reason
      } else {
        return
      }
    } else {
      return
    }
    // 先打自家 API 成功後再 sync 最新狀態至 firebase
    firebaseApi.putVerifyData(body)
      .then(() => {
        const isPass = type === 'pass'
        firebaseApi.setIsVerify(isPass, buildingId)
          .then(() => setStatus('complete'))
          .catch(error => {
            setStatus('error')
            console.log('setIsVerify', error)
          })
      })
      .catch(error => {
        setStatus('error')
        console.log('putVerifyData', error)
      })
  }

  return (
    <div className={`verify-section ${isVerify ? 'done' : ''}`}>
      {
        status === 'init' &&
        <button onClick={() => setStatus('verifying')} className="trigger-btn">店長驗收</button>
      }
      {
        status === 'verifying' &&
        <div className="check-box">
          <div className="back-section">
            <button onClick={() => setStatus('init')}>←</button>
          </div>
          <div className="action-section pass">
            <button onClick={() => submit('pass')}>✓ 通過</button>
          </div>
          <div className="action-section fail">
            <button onClick={() => setStatus('typing reason')}>✗ 不通過</button>
          </div>
        </div>
      }
      {
        status === 'typing reason' &&
        <div className="check-box">
          <div className="back-section">
            <button onClick={() => setStatus('verifying')}>←</button>
          </div>
          <div className="input-section">
            <label htmlFor="reason">請填寫原因：</label>
            <textarea id="reason" ref={inputRef} value={reason} onChange={e => setReason(e.target.value)} rows="12" cols="25" />
            { !reason && <span className="hint">必填</span> }
          </div>
          <div className="submit-section">
            <button onClick={() => submit('fail')}>傳送</button>
          </div>
        </div>
      }
      {
        status === 'complete' &&
        <button className="complete-text">已傳送</button>
      }
      {
        status === 'error' &&
        <button className="error-text">錯誤：請重整頁面</button>
      }
    </div>
  )
}

export default VerifySection