import { Vector3 } from 'three';

function extraVertices(data) {
  return data.layers['layer-1'].vertices;
}

function extraLines(data, verticesDist) {
  const lines = {};

  const rawData = data.layers['layer-1'].lines;
  if (rawData === undefined) return {};
  const keys = Object.keys(rawData);

  keys.forEach(key => {
    const line = rawData[key];
    if (line) {
      const startVertex = new Vector3(
        verticesDist[line.vertices[0]].x,
        0,
        verticesDist[line.vertices[0]].y
      );
      const endVertex = new Vector3(
        verticesDist[line.vertices[1]].x,
        0,
        verticesDist[line.vertices[1]].y
      );
      lines[key] = {
        start: startVertex,
        end: endVertex,
      };
    };

  });

  return lines;
}

function extraholes(data, verticesDist) {
  const holes = {};

  const rawData = data.layers['layer-1'].holes;
  if (rawData === undefined) return {};
  const keys = Object.keys(rawData);

  keys.forEach(key => {
    const hole = rawData[key];
    const width = hole.properties.width.length;
    const line = data.layers['layer-1'].lines[hole.line];
    if (line) {
      const startVertex = new Vector3(
        verticesDist[line.vertices[0]].x,
        0,
        verticesDist[line.vertices[0]].y
      );
      const endVertex = new Vector3(
        verticesDist[line.vertices[1]].x,
        0,
        verticesDist[line.vertices[1]].y
      );

      const dir = new Vector3().subVectors(endVertex, startVertex);
      let angle = new Vector3(1, 0, 0).angleTo(dir);
      let { offset } = hole;

      if (startVertex.z > endVertex.z) {
        angle = 2 * Math.PI - angle;
      }

      let rotation = angle;

      if (Math.abs(rotation - Math.PI) < 1) {
        rotation += Math.PI;
        offset = 1 - offset;
      }

      const flipOrizzontal = hole.properties.flip_orizzontal;
      if (flipOrizzontal) rotation += Math.PI;

      const pos = new Vector3().lerpVectors(startVertex, endVertex, offset);

      holes[key] = { pos, rotation, width, flipOrizzontal };
    }

  });

  return holes;
}

function extraArea(data, verticesDist) {
  const rawData = data.layers['layer-1'].areas;
  if (rawData === undefined) return {};

  const keys = Object.keys(rawData);

  const processData = {};
  keys.forEach(key => {
    const areaData = rawData[key];
    const verticesCoordinate = [];
    areaData.vertices.forEach(vertex => {
      verticesCoordinate.push(
        new Vector3(verticesDist[vertex].x, 0, verticesDist[vertex].y)
      );
    });
    processData[key] = verticesCoordinate;
  });
  return processData;
}

export { extraVertices, extraLines, extraArea, extraholes };
