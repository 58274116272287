import * as THREE from 'three';
import RenderOrder from './mat-manager';

const wallBoundingWidth = 0.005; // wall bounding init width
const wallBoundingColor = new THREE.Color('white'); // room bounding color

function createSingleWallBounding(pos, length, room) {
    // 將長度設為length+singleWallWidth (解決轉角沒有連接問題)
    const geometry = new THREE.BoxGeometry(
        length + wallBoundingWidth,
        wallBoundingWidth,
        wallBoundingWidth
    );
    const material = new THREE.MeshBasicMaterial({
        color: wallBoundingColor,
        transparent: true,
        opacity: 0.0,
        depthTest: true,
    });

    const line = new THREE.Mesh(geometry, material);
    line.renderOrder = RenderOrder.ruler
    line.name = 'ruler';
    line.length = length / Math.abs(room.mesh.position.y);
    line.roomId = room.mainRoomId;

    line.setVisible = function (visible) {
        if (visible) {
            line.material.opacity = 1;
            line.renderOrder = RenderOrder.ruler;
        }
        else {
            line.material.opacity = 0;
            line.renderOrder = RenderOrder.ruler + 1;
        }
    }

    line.getScreenPosition = function (viewerCameraControls) {
        const position = new THREE.Vector3();
        const rot = new THREE.Quaternion();
        const scale = new THREE.Vector3();
        line.updateMatrixWorld();
        line.matrixWorld.decompose(position, rot, scale);
        position.project(viewerCameraControls.camera);
        position.x = position.x * 0.5 + 0.5;
        position.y = -(position.y * 0.5) + 0.5;
        return position;
    }

    line.position.set(pos.x, 0, pos.y);

    return line;
}

function createRulers(
    roomsCarriers,
    scale,
) {
    const rulerGroup = new THREE.Group()
    Object.values(roomsCarriers).forEach(room => {
        const { points, mesh } = room;
        let bbox = new THREE.Box3().setFromObject(mesh);
        let size = new THREE.Vector3();
        bbox.getSize(size);

        const ceilingY = -mesh.position.y + size.y * scale;
        const floorY = -mesh.position.y;


        for (let j = 0; j < points.length; j += 1) {
            const pointA = points[j];
            let pointB = null;

            // 兩兩point跑所有layout points
            if (j === points.length - 1) {
                [pointB] = points;
            } else {
                pointB = points[j + 1];
            }

            // 計算point距離來當bounding長度
            const length = pointA.distanceTo(pointB);
            let topRuler = null;
            let downRuler = null;


            // 計算pointA,B畫出來的線對應 positive x-axis angle 來定義是直線或橫線
            let angleDeg =
                (Math.atan2(pointB.y - pointA.y, pointB.x - pointA.x) * 180) / Math.PI;
            angleDeg = Math.abs(angleDeg);

            // 依據point x,y座標來定義 wall bounding位置
            if (angleDeg <= 45 || angleDeg >= 135) {
                const midX = (pointA.x + pointB.x) / 2;
                const centerPos = new THREE.Vector2(midX, pointA.y);
                topRuler = createSingleWallBounding(centerPos, length, room);
                downRuler = createSingleWallBounding(centerPos, length, room);
            } else {
                const midY = (pointA.y + pointB.y) / 2;
                const centerPos = new THREE.Vector2(pointA.x, midY);
                topRuler = createSingleWallBounding(centerPos, length, room);
                topRuler.rotateY(Math.PI / 2);
                downRuler = createSingleWallBounding(centerPos, length, room);
                downRuler.rotateY(Math.PI / 2);
            }

            let verticalRuler = createSingleWallBounding(pointA, ceilingY - floorY, room);
            verticalRuler.rotateZ(Math.PI / 2);


            // wall bounding 加到 room model children 繼承position scale rotation
            mesh.add(topRuler);
            mesh.add(downRuler);
            topRuler.position.set(topRuler.position.x, ceilingY, topRuler.position.z);
            downRuler.position.set(downRuler.position.x, floorY, downRuler.position.z);

            mesh.add(verticalRuler);
            verticalRuler.position.set(verticalRuler.position.x, (ceilingY + floorY) / 2, verticalRuler.position.z);

            // re-attach to group parent (change parent but keep position, scale, rotation)
            rulerGroup.attach(topRuler);
            rulerGroup.attach(downRuler);
            rulerGroup.attach(verticalRuler);
        }
    });
    return rulerGroup
}

export default {
    createRulers
}
