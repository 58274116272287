import axios from 'axios';
import firebase from "firebase/app"
import "firebase/storage"
import "firebase/database"

const xhr = axios.create();
let firebaseConfig  = null;
let styleImageBucket = null;
const dir = process.env.REACT_APP_3DVIWER_CLOUD_FUNCTIONS;
let headers = {
  projectId: process.env.REACT_APP_3DVIWER_NAME,
};
if (process.env.REACT_APP_NODE_ENV === 'development') {
  headers.apiKey = 'AIzaSyCjgFBdfpToVv9bpRQDJ-73qfWBOaKQkPU';
  firebaseConfig = {
    apiKey: "AIzaSyDpWe0z46bbOxshuJu-zyBElHv214Z1v9g",
    authDomain: "vrcam-dev-5a815.firebaseapp.com",
    databaseURL: "https://vrcam-dev-5a815.firebaseio.com",
    projectId: "vrcam-dev-5a815",
    storageBucket: "vrcam-dev-5a815.appspot.com",
    messagingSenderId: "798666529342",
    appId: "1:798666529342:web:44711a6f3b865d8e608f6f"
  };
  styleImageBucket = "gs://vrcam-dev-5a815-styleimage"
} else if (process.env.REACT_APP_NODE_ENV === 'production') {
  headers.apiKey = 'AIzaSyCmkiGniS0o3QEugIY7j0Q9l3kJC4s8FI0';
  firebaseConfig = {
    apiKey: "AIzaSyAZ-2TTJsrDogtMuDvDGnWDaVFI36PS7ko",
    authDomain: "vr-cam-161603.firebaseapp.com",
    databaseURL: "https://vr-cam-161603.firebaseio.com",
    projectId: "vr-cam-161603",
    storageBucket: "vr-cam-161603.appspot.com",
    messagingSenderId: "585720971331",
    appId: "1:585720971331:web:ffb80fedcc546f094754f6"
  };
  styleImageBucket = "gs://vrcam-dev-5a815-styleimage"
}

let app = firebase.initializeApp(firebaseConfig);

const firebaseApi = {
  fetchBuilding(buildingId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${dir}buildings`, { data: { buildingId } }, { headers })
        .then(res => {
          const result = { data: res.data.result };
          if (result.data === null || buildingId === '') {
            resolve({ res: null });
          } else {
            resolve(result);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchMultifloorBuilding(buildingId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${dir}multifloorBuildings`, { data: { buildingId } }, { headers })
        .then(res => {
          const result = { data: res.data.result };
          if (result.data === null || buildingId === '') {
            resolve(null);
          } else {
            resolve(result.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchPanoramas(buildingId, onDownloadProgress) {
    const apiLink = process.env.REACT_APP_DB_API + 'api/v2/buildings/openLink/';
    return new Promise((resolve, reject) => {
      axios
        .get(apiLink + buildingId, { onDownloadProgress })
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
          console.error('Fetch Panorama error');
        });
    });
  },
  
  fetchModel(objectId, buildingId, modelType, onDownloadProgress) {
    return new Promise(async(resolve, reject) => {
        let url = `${dir}getObjFiles`;
        const request = xhr.request({
            method: 'POST',
            url,
            onUploadProgress: onDownloadProgress,
            responseType: 'json',
            headers,
            data: {
              data: {
                buildingId,
                panoId: objectId,
                type: `${modelType}.obj`,
              },
            },
        });
        request.then(res => {
          resolve(res.data.result);
        }).catch(error => {
          console.error(`Fetch ${modelType} Error`);
          reject(error);
        });
    });
  },

  fetchImage(url, onDownloadProgress){
    return new Promise(async(resolve, reject) => {
      const request = xhr.request({
        method: 'GET',
        url,
        onDownloadProgress,
      });
      request.then(res => {
        resolve(res.data.result);
      }).catch(error => {
        console.error(`Fetch ${url} Error`);
        reject(error);
      });
    });
  },

  fetchStyleImage(path, onDownloadProgress){
    return new Promise((resolve, reject) => {
      app.storage(styleImageBucket).ref(path).getDownloadURL().then(url=>{
        // preload 
        var img = new Image();
        img.src = url;
        resolve(url);
        
      }).catch(e=>{
        resolve(null)
      })
    })
  },

  fetchUserqrUrl(ownerId) {
    return new Promise((resolve, reject) => {
      let url =
        process.env.REACT_APP_DB_API + `api/v2/users/${ownerId}/publicProfile`;
      
      const request_profile = xhr.request({
        method: 'GET',
        url,
      });
      request_profile.then((res)=>{
        if(res.data.qrUrl){
          let qrUrl = res.data.qrUrl.split("{")[0]
          resolve(qrUrl)
        }else{
          resolve(`https://buy.yungching.com.tw/redirect/vr`)
        }
      })
    });
  },

  fetchQRCodeUrl(buildingId) {
    return new Promise((resolve, reject) => {
      const apiLink =
        process.env.REACT_APP_DB_API + 'api/v2/buildings/openLink/';
      let url = apiLink + buildingId;
      const request = xhr.request({
        method: 'GET',
        url,
      });
      request
        .then(async res => {
          let productNumber = res.data.productNumber;
          let ownerId = res.data.ownerId;

          let qrUrl = await this.fetchUserqrUrl(ownerId);
          console.log(qrUrl);
          console.log(productNumber);
          resolve(`${qrUrl}/${productNumber}`)
          
        })
        .catch(error => {
          console.error(`Fetch ${url} Error`);
          reject(error);
        });
    });
  },
  fetchIsVerify(buildingId) {
    return new Promise((resolve, reject) => {
      const buildingIsVerify = app.database().ref(`/3DLivetour/verify2dto3d/${buildingId}`)
      buildingIsVerify.get()
        .then(snapshot => {
          if (snapshot.exists()) {
            resolve(snapshot.val())
          } else {
            resolve(false)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  setIsVerify(isPass = false, buildingId = '') {
    return new Promise((resolve, reject) => {
      const buildingIsVerify = app.database().ref(`/3DLivetour/verify2dto3d`)
      buildingIsVerify.update(
        { [`/${buildingId}`]: { ycVerify: isPass } },
        (error) => {
          if (error) throw error
          else resolve()
        })
    })
  },

  putVerifyData(body = { objectId: '', status: ''}) {
    // ************************
    // body: {
    //   objectId: String,
    //   status: String,
    //   reason: String (Require when status === 'needRe-turn' )
    // }
    // ************************
    return new Promise((resolve, reject) => {
      const url = process.env.REACT_APP_DB_API + 'api/v2/buildings/openLink/' + body.objectId
      const request = xhr.request({
        method: 'PUT',
        url,
        data: body
      })
      request
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          console.error(`Put ${url} Error`)
          reject(error);
        })
    })
  }
};

export default firebaseApi;
