import React from 'react';
import { Trans } from 'react-i18next';
import { STATE } from 'services/3dviewer/moving-controls/synchronizeController'
import store from 'store';
import actions from 'store/actions';
import APIService from 'services/cloudApi.js';

import ruler from 'asset/image/ruler.svg';
import zoomOut from 'asset/image/zoomOut.svg';
import zoomIn from 'asset/image/zoomIn.svg';
import QRCode from 'asset/image/QRCode.svg';
import fullScreen from 'asset/image/fullScreen.svg';
import SvgFullscreen from 'components/svgFullscreen/SvgFullscreen';
import SvgShare from 'components/svgShare/SvgShare';
import SvgRuler from 'components/svgRuler/SvgRuler';

export default class ToolBar extends React.Component {
  constructor(props) {
    super(props);
    this.qrCodeUrl = '';
    this.state = {
      isTopview: true,
      isOpenRuler: false,
    }

    this.viewChange = this.viewChange.bind(this);
    this.rulerSwitch = this.rulerSwitch.bind(this);
  }

  componentWillUnmount(){
    this.unsubscribe();
  }

  viewChange() {
    const { viewButton } = store.getState();
    const { viewState } = viewButton;

    if (viewState === STATE.FPVIEW) {
      this.setState({
        isTopview: false
      });
    }
    else {
      this.setState({
        isTopview: true
      });
    }
  }

  rulerSwitch() {
    const { viewVariable } = store.getState();
    console.log(viewVariable)
    viewVariable.isDrawingRuler = !viewVariable.isDrawingRuler
    store.dispatch(actions.setViewVariable(viewVariable))
    this.setState({
      isOpenRuler: viewVariable.isDrawingRuler 
    })
  }

  zoomIn() {
    this.props.viewerCameraController.controls.zoomIn();
  }

  zoomOut() {
    this.props.viewerCameraController.controls.zoomOut();
  }

  linkToYcPage() {
    window.open(this.qrCodeUrl, '_blank').focus();
  }

  render() {
    const { queryStringList } = store.getState()
    const isShowRuler = queryStringList.ruler === 'true' ? true : false
    const isShowStyleBtn = queryStringList.style === 'true' ? true : false
    const { parent } = this.props;
    const toolbarClass = this.state.isTopview ? "tool-bar-topview" : "tool-bar";
    return (
      <div className={toolbarClass}>
        {
          isShowStyleBtn?
            <button
              className="show-style-button"
              onClick={() => parent.triggerStyleSelectWindow()}
            >
              <Trans>ActionShowChangeStyle</Trans>
            </button>
          :null
        }
        <div className="functional-tool-bar"
          style={{display:this.state.isTopview?'none':'flex'}}
        >
          { 
            isShowRuler?
              <SvgRuler func={this.rulerSwitch} ruler={ruler} isOpenRuler={this.state.isOpenRuler}/>
            : null
          }
          <SvgFullscreen />
          <div onClick={()=>parent.triggerQRCodeBoard()}>
            <SvgShare isOpenSharePopup={parent.state.openQRCodeBoard} />
          </div>
        </div>
      </div>
    );
  }

  async componentDidMount() {
    const { id } = store.getState();
    this.qrCodeUrl = await APIService.fetchQRCodeUrl(id);

    this.unsubscribe = store.subscribe(this.viewChange); // add moniter changes from store and set listener
    this.viewChange();
  }
}
