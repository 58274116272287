import React from 'react';
// import { isMobile } from 'react-device-detect';
import { Trans } from 'react-i18next';
import { STATE } from 'services/3dviewer/moving-controls/synchronizeController'
import store from 'store';
import rectangle from 'asset/image/rectangle.svg';
import back from 'asset/image/back.svg';
import hide from 'asset/image/hide.svg';
import FloorPlanCanvas from '../floorplan-canvas';
import './css/BtnStyle.scss';

export default class ViewChangeMenu extends React.Component {
  constructor(props) {
    super(props);

    let RWD = (window.innerWidth < 1366) ? 'Mobile' : 'PC';
    let orientation = window.matchMedia("(orientation: portrait)").matches ? 'portrait' : 'landscape';
    this.state = {
      displayMenu: false,
      displayBack: false,
      openMenu: true,
      RWD: RWD,
      orientation: orientation
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.viewChange = this.viewChange.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('orientationchange', this.updateDimensions);
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(this.viewChange); // add moniter changes from store and set listener
    this.viewChange();
  }

  componentWillUnmount(){
    this.unsubscribe();
  }

  toggleMenu() {
    this.setState({
      openMenu: !this.state.openMenu,
    });
  }

  viewChange() {
    const { viewButton, viewVariable } = store.getState();
    const { viewState } = viewButton;

    if (viewVariable.isCameraMoving) {
      this.setState({
        displayMenu: false,
        displayBack: false
      });
    }
    else {
      this.setState({
        displayMenu: viewState === STATE.FPVIEW,
        displayBack: !(viewState === STATE.FPVIEW)
      });
    }
  }

  updateDimensions()
  {
    if(window.innerWidth < 1366)
    {
      if(window.matchMedia("(orientation: portrait)").matches)
      {
        this.setState({
          RWD: 'Mobile',
          orientation: 'portrait'
        })
      }
      else{
        this.setState({
          RWD: 'Mobile',
          orientation: 'landscape'
        })
      }
    }
    else
    {
      this.setState({
        RWD: 'PC'
      })
    }
  }

  render() {
    const { viewButton } = store.getState();
    const { goDown, goUp, toOrthographics } = viewButton;
    const { displayMenu, displayBack, openMenu, RWD, orientation } = this.state;

    let rightOffset;
    if(RWD === 'Mobile')
    {
      if(orientation === 'portrait')
      {
        rightOffset = '50vw';
      }
      else
      {
        rightOffset = '25vw';
      }
    }
    else
    {
      rightOffset = '15vw';
    }

    const buttonOpenStyle = {
      right: openMenu ? rightOffset : '0px',
      transform: openMenu ? 'scaleX(1)' : 'scaleX(-1)',
      borderRadius: openMenu ? '3px 0px 0px 3px' : '0px 3px 3px 0px'
    };

    const menuOpenStyle = {
      display: (displayMenu && openMenu) ? '' : 'none'
    }

    return (
      <div className="view-menu-container">
        {
          displayBack &&
          <button
            className="insight-viewButton"
            type="button"
            onClick={goDown}
          >
            <img src={back} alt="back" />
          </button>
        }
        {
          displayMenu &&
          <button
            className="hide-viewBtn-container"
            type="button"
            style={buttonOpenStyle}
            onClick={this.toggleMenu}
          >
            <img src={hide} alt="hide" />
          </button>
        }
        <div className="viewBtn-container" style={menuOpenStyle}>
          <button
            className="floorplan-viewButton"
            type="button"
            hidden={false}
            onClick={() => {
              toOrthographics();
            }}
          >
            <FloorPlanCanvas open={displayMenu && openMenu} viewerCameraController={this.props.viewerCameraController} />
          </button>
          <button
            className="dollhouse-viewButton"
            type="button"
            hidden={false}
            onClick={() => {
              goUp();
            }}
          >
            <img src={rectangle} alt="rectangle" />
            <i className="icon-3d-view"> </i>
            <span className="buttonText">
              &nbsp;
              <Trans>ViewModeDollhouseView</Trans>
            </span>
          </button>
        </div>
      </div>
    );
  }
}
