import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import APIService from 'services/cloudApi.js';
import store from 'store';
import actions from 'store/actions';
import dataFunctions from 'services/utils/datafunctions';
import CubemapHelper from 'asset/js/cubemap-helper';
import { loadTexture } from 'services/loaders/texture-loader';
import { parseObject } from 'services/loaders/object-loader';
import ProgressBar from './progressbar';
import './css/launcher.scss';

export default class Launcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedArr: [],
      total: 100,
    };
    
  }

  componentDidMount() {
    this.init();
  }

  get loaded() {
    const { loadedArr } = this.state;
    return _.sum(loadedArr);
  }

  async checkMultiFloor() {
    const { buildingId } = this.props;
    const data = await APIService.fetchMultifloorBuilding(buildingId);

    if (data) {
      return data.floors;
    }
    else {
      return null;
    }
  }

  async init() {
    let buildingData = {};

    let multifloor = await this.checkMultiFloor()

    if (multifloor) {
      let multifloorId = multifloor.data.map(data => data.ID)
      buildingData.floorList = multifloorId;
      
      // TODO : deal with multifloor data
      for (let i = 0; i < multifloorId.length; i++) {
        let floorId = multifloorId[i]
        let floorData = await this.fetchStylesAndBuildingData(floorId);
        floorData.name = multifloor.data[i].name
        buildingData[floorId] = floorData;
        this.fetchHierarchy(floorData);

        this.fetchLabels(floorData);
        await this.preload(floorData);
        store.dispatch(actions.setCurrentFloorId(floorId))
        store.dispatch(actions.setID(floorId))
      }
      store.dispatch(actions.setCurrentFloorId(multifloorId[multifloor.defaultIndex]))

    } else {
    
      const { buildingId } = this.props;
      buildingData.floorList = [buildingId];

      let floorData = await this.fetchStylesAndBuildingData(buildingId);
      buildingData[buildingId] = floorData;
      this.fetchHierarchy(floorData);

      this.fetchLabels(floorData);
      await this.preload(floorData);
      store.dispatch(actions.setCurrentFloorId(buildingId))
      store.dispatch(actions.setID(buildingId))
    }
       
    store.dispatch(actions.setBuilding(buildingData))
    console.log(buildingData)

    const { onReady } = this.props;
    onReady();
  }

  async preload(floorData) {
    // prepare source of all 1x6Cubemaps
    let panoramasData = [];

    for (let i = 0; i < floorData.styles.length; i++) {
      const { panoramas } = await APIService.fetchPanoramas(
        floorData.styles[i].buildingId
      );
      panoramasData = panoramasData.concat(panoramas);
    }
    const mainRoomData = this.fetchMainRoomData(floorData, panoramasData);
    const cubemapUrls1x6 = [];
    const panoramaImagesUrls = [];

    mainRoomData.forEach(panorama => {
      const { objectId, thumbnail } = panorama;
      floorData.panoramaImages[objectId] = thumbnail;
      panoramaImagesUrls.push({
        objectId,
        url: thumbnail,
        type: 'panorama',
      });
    });
    panoramasData.forEach(panorama => {
      const { cubemapFilePath, objectId } = panorama;
      const { preview, cubemap } = CubemapHelper.generateCubemapSource(
        cubemapFilePath
      );
      cubemapUrls1x6.push({
        objectId,
        url: preview,
        type: 'cubemaps',
      });
      floorData.cubemapUrls[objectId] = cubemap;
      floorData.cubemapUrls1x6[objectId] = preview;
    });
    // prepare source of models
    const mainRoomsId = dataFunctions.getMainRoomId(
      floorData.data.group
    );

    const roomModels = [];
    const doorModels = [];
    mainRoomsId.forEach(id => {
      roomModels.push({
        objectId: id,
        url: null,
        type: 'layout_noDoor',
      });
      doorModels.push({
        objectId: id,
        url: null,
        type: 'layout_door',
      });
    });

    // start preload
    const queue = [];
    const source = _.concat(roomModels, doorModels, cubemapUrls1x6);
    this.setState({ total: 100 * source.length });
    
    source.forEach((object, index) => {
      const { objectId, url, type } = object;
      if (type === 'cubemaps' || type === 'panorama') {
        const promise = APIService.fetchImage(url, event => {
          const { total, loaded } = event;
          const { loadedArr } = this.state;
          const precent = (100 * loaded) / total;
          const arrClone = loadedArr.slice();
          arrClone[index] = precent;
          this.setState({ loadedArr: arrClone });
        })
          .then(async () => {
            // generate texture by three.js textureloader.
            if (type === 'cubemaps') {
              const texture = await loadTexture(url);
              const textureObject = texture;
              textureObject.name = objectId;
              floorData.dollHouseCubemapsData[objectId] = textureObject;
            } else if (type === 'panorama') {
              floorData.panoramaImages[objectId] = url;
            }
          })
          .catch(error => {
            console.log(error);
          });
        queue.push(promise);
      } else {
        const { id, panoIds } = floorData;
        const promise = APIService.fetchModel(
          objectId,
          id,
          type,
          event => {
            const { total, loaded } = event;
            const precent = (100 * loaded) / total;
            const { loadedArr } = this.state;
            const arrClone = loadedArr.slice();
            arrClone[index] = precent;
            this.setState({ loadedArr: arrClone });
          }
        )
          .then(async res => {
            // generate model as group by three.js objLoader.
            const model = await parseObject(res);
            // const id = objId2IdTable[objectId];
            floorData.styles.forEach(style => {
              const remapId = style.panoIds[panoIds.indexOf(objectId)];
              if (type === 'layout_door') {
                // save to an object for later storage.
                floorData.doorModelData[remapId] = model.clone(); // {'id:model'}.
              } else if (type === 'layout_noDoor') {
                floorData.roomModelData[remapId] = model.clone(); // {'id:model'}.
              }
            });
          })
          .catch(error => {
            console.log(error);
          });
        queue.push(promise);
      }
    });
    return Promise.all(queue);
  }

  fetchMainRoomData(floorData, panoramasData) {
    let mainRoomIds = [];
    floorData.hierarchy.forEach(building => {
      mainRoomIds = mainRoomIds.concat(Object.keys(building.panoramas));
    });

    const mainRoomData = [];
    mainRoomIds.forEach(mainRoomId => {
      panoramasData.forEach(panorama => {
        const { objectId } = panorama;
        if (objectId === mainRoomId) {
          mainRoomData.push(panorama);
        }
      });
    });
    return mainRoomData;
  }

  getPanoIds(panoramas, originalPanoramas) {
    let originalPanoIndex = [];
    originalPanoramas.forEach(pano => {
      originalPanoIndex.push(pano.index);
    });

    let panoDict = {};
    panoramas.forEach(pano => {
      panoDict[pano.index] = pano.objectId;
    });

    let panoIds = [];
    originalPanoIndex.forEach(index => {
      panoIds.push(panoDict[index]);
    });

    return panoIds;
  }

  async fetchStylesAndBuildingData(buildingId) {

    const buildingPanos = await APIService.fetchPanoramas(buildingId);

    let floorData = {
      styles: [],
      data: "",
      panoIds: [],
      id: "",
      dollHouseCubemapsData: {},
      doorModelData:{},
      roomModelData:{},
      cubemapUrls:{},
      cubemapUrls1x6:{},
      panoramaImages:{},
      hierarchy:{},
      labels: {},
      panoramaSort:[]
    };

    let styles = [
      {
        name: 'origin',
        buildingId: buildingId,
        panoIds: this.getPanoIds(
          buildingPanos.panoramas,
          buildingPanos.panoramas
        ),
        imageUrl: ""
      },
    ];

    floorData.panoramaSort.push([...buildingPanos.panoramas].sort(function (a, b) {
      return a.index - b.index;
     }));

    if (buildingPanos.multiStyle !== undefined) {
      // from database
      let multiStlye = JSON.parse(buildingPanos.multiStyle);
      for (let i = 0; i < multiStlye.length; i++) {
        const style = multiStlye[i];
        const { name, buildingId, stylePath } = style;
        if(buildingId != ""){
          const { panoramas } = await APIService.fetchPanoramas(buildingId);
          let panoIds = this.getPanoIds(panoramas, buildingPanos.panoramas);
          let imageUrl = await APIService.fetchStyleImage(stylePath, null);
          
          styles.push({ name, buildingId, panoIds, imageUrl });

          floorData.panoramaSort.push([...panoramas].sort(function (a, b) {
            return a.index - b.index;
           }));
        }
      }
    }
    const data = await APIService.fetchBuilding(buildingId);
    floorData.data = data.data;
    floorData.styles = styles;
    floorData.panoIds = this.getPanoIds(
      buildingPanos.panoramas,
      buildingPanos.panoramas
    );
    floorData.id = buildingId;
    
    return floorData;
  }

  fetchHierarchy(floorData) {
    const hierarchys = [];

    floorData.styles.forEach(style => {
      const { panoIds, buildingId } = style;
      const { group } = floorData.data;
      const buildingPanoArr = floorData.panoIds;

      const hierarchy = {
        buildingId: buildingId,
        panoramas: {},
      };
      group.forEach((roomData, index) => {
        const mainRoomId = _.get(roomData.layout, 'mainroom');
        const mapRoomId = panoIds[buildingPanoArr.indexOf(mainRoomId)];
        let newRoomData = JSON.parse(JSON.stringify(roomData));
        newRoomData.layout.mainroom = mapRoomId;
        newRoomData.hotspot.spot.forEach(spot => {
          spot.member = panoIds[buildingPanoArr.indexOf(spot.member)];
        });
        hierarchy.panoramas[mapRoomId] = newRoomData;
      });

      hierarchys.push(hierarchy);
    });

    floorData.hierarchy = hierarchys
  }

  fetchLabels(floorData) {
    const labels = {};
    floorData.styles.forEach(style => {
      const { data, panoIds } = floorData;
      Object.keys(data.panoramas).forEach(key => {
        const mapPanoId = style.panoIds[panoIds.indexOf(key)];
        labels[mapPanoId] = data.panoramas[key];
      });
    });

    floorData.labels = labels;
  }

  render() {
    const { total } = this.state;
    return (
      <div className="launcher">
        <div className="launcher-container">
          <ProgressBar value={this.loaded} max={total} />
        </div>
      </div>
    );
  }
}

Launcher.propTypes = {
  onReady: PropTypes.func.isRequired,
};
