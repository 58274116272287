import * as THREE from 'three';
import MyOrbitControls from './myOribitControl'
const FACTOR = {
  INIT: {
    ANIMATIONANGLE: Math.PI / 1.5,
    ZOOM: 0.7,
    POLARANGLE: Math.PI / 4,
    // init animation Will rotate ANIMATIONANGLE;
    AZIANGLE: Math.PI / 3,
  },
  CONSTARINT: {
    ROTATESPEED: 0.023,
    PANSPEED: 0.05,
    DAMPING: 0.05,

    MAX_ZOOM: 2,
    MIN_ZOOM: 0.8,

    TOPVIEW: {
      minPolarAngle: 0,
      maxPolarAngle: Math.PI / 2,
    },
    FPVIEW: {
      minPolarAngle: 0,
      maxPolarAngle: Math.PI,
    },
    MAXPAN: 0.5,
  },
};

class ViewerCameraController {
  init(canvas) {
    const camera = new THREE.PerspectiveCamera(
      75,
      canvas.clientWidth / canvas.clientHeight,
      0.01,
      1000
    );
    const controls = new MyOrbitControls(camera);
    // 畫布設置
    this.renderer = new THREE.WebGLRenderer({
      canvas,
      alpha: true,
      antialias: true,
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(canvas.clientWidth, canvas.clientHeight, false);
    this.renderer.autoClear = false;
    this.renderer.setClearColor(0x000000, 0.0);
    const self = this;
    this.windowResized = () => {
      if (self.camera != null) {
        self.camera.aspect = canvas.clientWidth / canvas.clientHeight;
        self.camera.updateProjectionMatrix();
      }
      self.renderer.setSize(canvas.clientWidth, canvas.clientHeight, false);
    };
    window.addEventListener('resize', this.windowResized, false);

    // init camera
    this.defaultFov = 75;
    this.camera = camera

    this.camera.position.set(0, 1, 0);
    this.controls = controls
    this.controls.target.set(0, 0, 0);
    this.controls.domElement = this.renderer.domElement;
    this.controls.enableDamping = true;

    this.controls.dampingFactor = FACTOR.CONSTARINT.DAMPING;
    this.controls.panSpeed = FACTOR.CONSTARINT.PANSPEED;
    this.controls.rotateSpeed = FACTOR.CONSTARINT.ROTATESPEED;

    this.controls.maxPolarAngle = Math.PI / 2;

    // set initial pose
    this.controls.minZoom = FACTOR.CONSTARINT.MIN_ZOOM;
    this.controls.maxZoom = FACTOR.CONSTARINT.MAX_ZOOM;

    this.polarAngle = FACTOR.INIT.POLARANGLE;
    this.azimuthAngle = FACTOR.INIT.AZIANGLE + FACTOR.INIT.ANIMATIONANGLE;
    this.controls.update();

    this.controls.panConstrain = (target, offset) => {
      const newTargetPos = new THREE.Vector3(
        target.x + offset.x,
        target.y + offset.y,
        target.z + offset.z
      );

      return newTargetPos.length() <= FACTOR.CONSTARINT.MAXPAN;
    };

    return this;
  }

  render(scene) {
    this.controls.update();
    this.renderer.render(scene, this.camera);
  }

  setOrthographicLimit(pos) {
    this.controls.target.set(pos.x, pos.y, pos.z);

    this.controls.minPolarAngle = 0;
    this.controls.maxPolarAngle = 0;
    this.controls.rotateSpeed = FACTOR.CONSTARINT.ROTATESPEED;
    this.controls.enableDamping = true;
    this.controls.enableRotate = true;

    this.controls.update();
  }

  setTopViewLimit(pos) {
    this.controls.target.set(pos.x, pos.y, pos.z);

    this.controls.minPolarAngle = FACTOR.CONSTARINT.TOPVIEW.minPolarAngle;
    this.controls.maxPolarAngle = FACTOR.CONSTARINT.TOPVIEW.maxPolarAngle;

    this.controls.rotateSpeed = FACTOR.CONSTARINT.ROTATESPEED;

    this.controls.enableDamping = true;
    this.controls.enableRotate = true;

    this.controls.update();
  }

  setFirstpersonViewLimit() {
    const vec = new THREE.Vector3(0, 0, -0.00001);
    vec.applyQuaternion(this.camera.quaternion);
    this.controls.target.set(
      this.camera.position.x + vec.x,
      this.camera.position.y + vec.y,
      this.camera.position.z + vec.z
    );

    this.controls.minPolarAngle = FACTOR.CONSTARINT.FPVIEW.minPolarAngle;
    this.controls.maxPolarAngle = FACTOR.CONSTARINT.FPVIEW.maxPolarAngle;

    this.controls.rotateSpeed = -FACTOR.CONSTARINT.ROTATESPEED;

    this.controls.enableDamping = true;
    this.controls.enableRotate = true;

    this.controls.update();
  }

  setGoinglimit(dstIsTop) {
    const vec = new THREE.Vector3(0, 0, -0.001);
    vec.applyQuaternion(this.camera.quaternion);
    this.controls.target.set(
      this.camera.position.x + vec.x,
      this.camera.position.y + vec.y,
      this.camera.position.z + vec.z
    );

    this.controls.minPolarAngle = 0;
    this.controls.maxPolarAngle = Math.PI;

    this.controls.rotateSpeed =
      (dstIsTop ? 1 : -1) * FACTOR.CONSTARINT.ROTATESPEED;
    this.controls.enableRotate = !dstIsTop;
    this.controls.enableDamping = !dstIsTop;

    this.controls.update();
  }

  setCamerPos(newPos, holdDirection) {
    const oldPos = new THREE.Vector3().copy(this.camera.position);

    const deltaPos = new THREE.Vector3(
      newPos.x - oldPos.x,
      newPos.y - oldPos.y,
      newPos.z - oldPos.z
    );

    this.camera.position.x += deltaPos.x;
    this.camera.position.y += deltaPos.y;
    this.camera.position.z += deltaPos.z;

    if (holdDirection === true) {
      this.controls.target.x += deltaPos.x;
      this.controls.target.y += deltaPos.y;
      this.controls.target.z += deltaPos.z;
    }
  }

  setLookAt(newLookAt) {
    this.controls.target.set(newLookAt.x, newLookAt.y, newLookAt.z);
  }

  set polarAngle(newValue) {
    const oldmin = this.controls.minPolarAngle;
    const oldmax = this.controls.maxPolarAngle;

    this.controls.minPolarAngle = newValue;
    this.controls.maxPolarAngle = newValue;
    this.controls.update();

    this.controls.minPolarAngle = oldmin;
    this.controls.maxPolarAngle = oldmax;
    this.controls.update();
  }

  set azimuthAngle(newValue) {
    const oldmin = this.controls.minAzimuthAngle;
    const oldmax = this.controls.maxAzimuthAngle;

    // fix coordinate , origion coordinate range is [-Math.PI,-0] [0,Math.PI] and ±Math.PI are neighbors
    // new value could be [ 0 , 2*Math.PI ]  or [ -0 , -2*Math.PI ]
    let fixCoordinate = newValue;

    if (fixCoordinate > Math.PI) {
      fixCoordinate = newValue - Math.PI + -Math.PI;
    } else if (fixCoordinate < -Math.PI) {
      fixCoordinate = newValue + Math.PI + Math.PI;
    }

    this.controls.minAzimuthAngle = fixCoordinate;
    this.controls.maxAzimuthAngle = fixCoordinate;
    this.controls.update();

    this.controls.minAzimuthAngle = oldmin;
    this.controls.maxAzimuthAngle = oldmax;
    this.controls.update();
  }

  get polarAngle() {
    return this.controls.getPolarAngle();
  }

  get azimuthAngle() {
    return this.controls.getAzimuthalAngle();
  }

  setLookAtCenter(target) {
    this.controls.target.set(target.x, target.y, target.z);
  }

  getLookAtCenter() {
    return this.controls.target;
  }

  getCamerPosition() {
    const cameraWorldPos = new THREE.Vector3();
    this.camera.getWorldPosition(cameraWorldPos);
    return cameraWorldPos;
  }

  getCameraQuaternion() {
    return this.camera.quaternion.clone();
  }

  get screenHeight() {
    const srceenY =
      Math.tan(this.fov / 2) *
      new THREE.Vector3()
        .subVectors(this.getCamerPosition(), this.getLookAtCenter())
        .length() *
      2;
    return srceenY;
  }

  set fov(value) {
    this.camera.fov = (value / Math.PI) * 180;
    this.camera.updateProjectionMatrix();
  }

  get fov() {
    this.camera.updateProjectionMatrix();
    return (this.camera.fov / 180) * Math.PI;
  }
}


const viewerCameraControllers = []

function createviewerCameraControllers(id) {
  viewerCameraControllers.push(new ViewerCameraController())
  return viewerCameraControllers.length - 1
}

// const viewerCameraControls = new ViewerCameraController();
// const viewerCameraControls2 = new ViewerCameraController();

export { FACTOR, createviewerCameraControllers, viewerCameraControllers };
