export default `
varying vec3 modelPosition;
    
uniform samplerCube tEquirect0;
uniform samplerCube tEquirect1;

uniform vec4 textureConfig0;
uniform vec4 textureConfig1;

uniform lowp float time;

mediump vec4 getColor(vec3 point,samplerCube texture, vec4 textureConfig){

    point.x = point.x - textureConfig.x;
    point.y = point.y - textureConfig.y;
    point.z = point.z + textureConfig.z;

    float inverRot = -textureConfig.w;
    mat4 rotationMat = mat4(cos(inverRot), 0.0, -sin(inverRot), 0.0,  
                            0.0, 1.0, 0.0, 0.0, 
                            sin(inverRot), 0.0, cos(inverRot), 0.0,  
                            0.0, 0.0, 0.0, 1.0);

    vec3 modelVertex = (vec4(point,1.0)*rotationMat).xyz;

    return vec4(textureCube(texture, modelVertex).xyz, 1.0);
}

void main()	{	    

    lowp vec4 color0 = getColor(modelPosition,tEquirect0,textureConfig0);
    lowp vec4 color1 = getColor(modelPosition,tEquirect1,textureConfig1);
  
    vec3 mixColor = mix(color0.xyz,color1.xyz,time);
   
    gl_FragColor = vec4(mixColor.xyz, 1.0);
}
`;
