import React, { Component } from 'react';
import {DROPDOWN} from '../config';
import  './swiper.scss'
import store from 'store';

export default class Swiper extends Component {
    constructor(props){
        super(props);
        let info = this.props.info;
        this.state = {
            // mode: DROPDOWN.OPEN,
            RWD: info.RWD,
            crntIndex: info.crntIndex,
            startIndex: info.startIndex,
            showLength: info.showLength,
            indexInterval: info.indexInterval,
            topOffset: info.topOffset,
            leftOffset: info.leftOffset,
            buttonSize: info.buttonSize,
            selectedBgColor: info.selectedBgColor,
            selectedTextColor: info.selectedTextColor,
            options: info.options,
            parent: this.props.parent
        };
        this.updateList = this.updateList.bind(this);
    }

    handleClick = (value, id=null) => {
        let newIndex = this.state.crntIndex;
        this.state.options.forEach((e, key) => {       
            if(e.id === id)
            {
                newIndex = key;
            }
        })
        
        let crntIndex = this.state.crntIndex;
        if(value !== crntIndex)
        {
            this.setState({
                crntIndex: value
            })
            this.state.parent.handleClick(value, id);
        }
    }

    updateList(options)
    {
        this.setState({
            options: options
        })
    }

    render() {
        const {building,currentRenderFloorID} = store.getState()
        const panoramaImagesObj = building[currentRenderFloorID].panoramaImages;
        const roomDataArray = this.state.options
        const isOpenSwiper = this.props.isOpenSwiper===DROPDOWN.OPEN ? true:false
        return(<>
            { 
                (<div className="swiper" 
                    style={isOpenSwiper?{bottom:'0px'}:{bottom:'-200px'}}
                    >
                    <div className="swiper-toggle-btn"
                        onClick={()=>this.state.parent.toggleSwiper()}
                    >
                        <div className='swiper-toggle-btn-icon'
                            style={isOpenSwiper?{transform:'rotate(-135deg)'}:{transform:'rotate(45deg)'}}
                        ></div>
                    </div>
                    <div className="swiper-panel">
                        <div className="scroll-container">
                            {
                                roomDataArray.length >0?
                                roomDataArray.map((roomData)=>{
                                    const roomVal = roomData.value
                                    const roomId = roomData.id
                                    return (
                                    <div className='swiper-item' key={roomId}>
                                        <img src={panoramaImagesObj[roomId]}
                                            style={roomVal === this.state.crntIndex?{border:'solid rgb(222,37,116) 3px'}:{}} 
                                            onClick = {(event) => {this.handleClick(roomVal, roomId); event.stopPropagation();}}
                                        />
                                        <div>{roomData.label || 'ROOM'}</div>
                                    </div>)
                                })
                                :null
                            }
                        </div>
                    </div>
                </div>)
            }</>
        );
    }
}
