import * as THREE from 'three';

const MOUSE_STATE = {
  MOUSE_DOWN: 0,
  MOUSE_UP: 1,
};
function MouseControls(viewerCameraController) {
  const DRAG_THRESHOLD = 0.2;
  const mouse = new THREE.Vector2();
  let status = MOUSE_STATE.MOUSE_UP;
  let deltaMove = 0;
  function recordMousePos(event) {
    const rendererSize = new THREE.Vector2();
    const renderInfo = viewerCameraController.renderer.domElement.getBoundingClientRect()
    viewerCameraController.renderer.getSize(rendererSize);
    if (event.type.includes('touch')) {
      mouse.x = ((event.changedTouches[0].clientX - renderInfo.x) / rendererSize.x) * 2 - 1;
      mouse.y = -((event.changedTouches[0].clientY - renderInfo.y) / rendererSize.y) * 2 + 1;
    } else {
      mouse.x = ((event.clientX - renderInfo.x) / rendererSize.x) * 2 - 1;
      mouse.y = -((event.clientY - renderInfo.y) / rendererSize.y) * 2 + 1;
    }
  }
  function update(event) {
    switch (event.type) {
      case 'mousedown':
      case 'touchstart':
        this.recordMousePos(event);
        status = MOUSE_STATE.MOUSE_DOWN;
        deltaMove = 0;
        break;
      case 'mousemove':
      case 'touchmove':
        if (status === MOUSE_STATE.MOUSE_DOWN) {
          const lastMousePos = mouse.clone();
          this.recordMousePos(event);
          deltaMove += lastMousePos.distanceTo(mouse);
        } else {
          this.recordMousePos(event);
        }
        if (status === MOUSE_STATE.MOUSE_DOWN);
        break;
      default:
        break;
    }
  }
  function isDragging() {
    status = MOUSE_STATE.MOUSE_UP;
    return deltaMove > DRAG_THRESHOLD;
  }
  // methods
  this.isDragging = isDragging;
  this.update = update;
  this.recordMousePos = recordMousePos;
  // getters
  this.mouse = mouse;
  Object.defineProperty(this, 'mouse', {
    value: this.mouse,
    enumerable: true,
  });
}

export default MouseControls;
