import React, { Component } from 'react';
import { DROPDOWN } from '../config';
import Swiper from './swiper'
import store from 'store';

export default class RoomSelectionList extends Component {
    constructor(props){
        super(props);
        let topOffset = 20;
        let leftOffset = 20;
        if(this.props.alignLeft)
        {
            if(this.props.RWD === "Mobile")
            {
                topOffset = 48;
                leftOffset = 0;
            }
        }
        else
        {
            if(this.props.RWD === "PC")
            {
                topOffset = 20;
                leftOffset = 190;
            }
            else
            {
                topOffset = 48;
                leftOffset = 100;
            }
        }
        this.state = {
            mode: DROPDOWN.OPEN,
            RWD: this.props.RWD,
            crntIndex: this.props.currentRoom,
            startIndex: 0,
            showLength: this.props.RWD==='PC'?7:1,
            indexInterval: this.props.RWD==='PC'?[0, 1, 2, 3, 4,5,6]:[0],
            topOffset: topOffset,
            leftOffset: leftOffset,
            buttonSize: this.props.RWD === "PC" ? [150, 50] : [100, 40],
            selectedBgColor: [85, 85, 85, 0.6],
            selectedTextColor: [237,12,116, 1.0],
            options: this.props.options,
        };
        this.updateList = this.updateList.bind(this);
    }

    updateList(options)
    {
        this.setState({
            options: options
        })
        this.dropdown.updateList(options);
    }

    closeList(panoId)
    {
        this.dropdown.handleClick("close", panoId);
    }

    handleClick(value, id=null) {
        console.log("Select room: " + value);
        console.log("room id: " + id);
        const { viewButton } = store.getState();
        const { goDependOnState } = viewButton;
        goDependOnState(id);
        this.props.parent.setState({currentRoom: value})
    }

    toggleSwiper(){
        if(this.state.mode===DROPDOWN.OPEN){
            this.setState({
                mode: DROPDOWN.CLOSE
            })
        }else{
            this.setState({
                mode: DROPDOWN.OPEN
            })
        }
    }

    render() {
        return <Swiper 
            info={this.state} 
            parent={this} 
            ref={ref => {this.dropdown = ref}}
            isOpenSwiper={this.state.mode}
        />
    }
}
