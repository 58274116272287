import store from 'store';
import actions from 'store/actions';
import { createViewBtn, updateViewBtn, getCorespondHotspot } from "./utils";

const STATE = {
    FPVIEW: 'DOWN',
    TOPVIEW: 'TOP',
    FLOORPLAN: 'FLOORPLAN'
}

class TwoSceneSynchronizeController {
    constructor() {
        this.leftScene = null;
        this.rightScene = null;
        this.sceneInfos = []
        this.viewBtnFuncs = {}
        this.state = null;
        this.movingState = false;

        this.setState = (state, isMoving = true) => {
            switch (state) {
                case STATE.FPVIEW:
                    updateViewBtn(this.viewBtnFuncs, STATE.FPVIEW);
                    this.state = state;
                    this.isMoving = isMoving;
                    this.movingState = isMoving;
                    break;
                case STATE.TOPVIEW:
                    updateViewBtn(this.viewBtnFuncs, STATE.TOPVIEW);
                    this.state = state;
                    this.isMoving = isMoving;
                    this.movingState = isMoving;
                    break;
                case STATE.FLOORPLAN:
                    updateViewBtn(this.viewBtnFuncs, STATE.FLOORPLAN);
                    this.state = state;
                    this.isMoving = isMoving;
                    this.movingState = isMoving;
                    break;
                default:
                    updateViewBtn(this.viewBtnFuncs, this.state)
                    break;
            }
            
            if (isMoving) {
                // stop all pointer / touch events
                let div = document.getElementById('root');
                div.style.pointerEvents = 'none';

                document.addEventListener('contextmenu', this.stopPropagation);

                document.addEventListener('mousedown', this.stopPropagation);
                document.addEventListener('wheel', this.stopPropagation);

                document.addEventListener('touchstart', this.stopPropagation);
                document.addEventListener('touchend', this.stopPropagation);
                document.addEventListener('touchmove', this.stopPropagation);
            }
            else {
                // resume events
                let div = document.getElementById('root');
                div.style.pointerEvents = '';

                document.removeEventListener('contextmenu', this.stopPropagation);

                document.removeEventListener('mousedown', this.stopPropagation);
                document.removeEventListener('wheel', this.stopPropagation);

                document.removeEventListener('touchstart', this.stopPropagation);
                document.removeEventListener('touchend', this.stopPropagation);
                document.removeEventListener('touchmove', this.stopPropagation);
            }

            this.handleMesh(state, this.leftScene);
            this.handleMesh(state, this.rightScene);
        }
    }
    get isMoving() {
        const { viewVariable } = store.getState();
        return viewVariable.isCameraMoving;
    }
    set isMoving(status) {
        const { viewVariable } = store.getState();
        viewVariable.isCameraMoving = status
        store.dispatch(actions.setViewVariable(viewVariable))
    }

    stopPropagation = (event) => {
        event.stopPropagation();
    }

    handleMesh = (state, scene) => {
        const { mesh } = scene.movingController.fpviewMesh;
        switch (state) {
            case STATE.FPVIEW:
                if (mesh.parent === null) scene.scene.add(mesh);
                break;
            case STATE.TOPVIEW:
                if (mesh.parent !== null) scene.scene.remove(mesh);
                break;
            case STATE.FLOORPLAN:
                if (mesh.parent !== null) scene.scene.remove(mesh);
                break;
            default:
                break;
        }
    }

    reset() {
        this.leftScene = null;
        this.rightScene = null;
        this.sceneInfos = []
        this.viewBtnFuncs = {}
        this.state = null;
    }

    init(onReady, cache = null) {
        this.rightScene.init();
        this.leftScene.init();
        this.sync();

        /// caching works only in FPVIEW !!! ///
        if (cache) {
            this.setState(STATE.FPVIEW);
            const [waitLeftRotate, waitLeftMove] = this.rightScene.start(cache);
            const [waitRightRotate, waitRightMove] = this.leftScene.start(cache);
    
            Promise.all([waitLeftRotate, waitRightRotate]).then(() => {
                this.setState(STATE.FPVIEW, false);
            });

            onReady();
            return;
        }
        /////////////

        this.setState(STATE.TOPVIEW);

        const [waitLeftRotate, waitLeftMove] = this.rightScene.start();
        const [waitRightRotate, waitRightMove] = this.leftScene.start();

        Promise.all([waitLeftRotate, waitRightRotate]).then(() => {
            this.setState(STATE.TOPVIEW, false);

            if (this.leftScene.movingController.firstMove2MainRoom) {
                this.setState(STATE.FPVIEW);

                Promise.all([waitLeftMove, waitRightMove]).then(() => {
                    this.setState(STATE.FPVIEW, false);
                });
            }
        });

        onReady();
    }

    cache = () => {
        return {
            hotspot: this.leftScene.movingController.destinationHotspot,
            position: this.leftScene.viewerCameraController.camera.position,
            rotation: this.leftScene.viewerCameraController.camera.rotation
        }
    }

    initViewBtnFuncs(STATE) {
        let funcs = {}
        for (let key in STATE) {
            funcs[STATE[key]] = {
                'goDown': [],
                'goUp': [],
                'toOrthographics': [],
                'goDependOnState': [],
                'viewState': [],
            }
        }
        return funcs
    }

    setRenderScenes(leftScene, rightScene) {
        this.leftScene = leftScene;
        this.rightScene = rightScene;
    }

    setSceneInfo(info) {
        this.sceneInfos.push(info);
    }

    changeStyle(index) {
        this.setState(null);
        return new Promise((resolve) => {
                const wait = this.rightScene.changeStyle(index);
                this.sync();
                wait.then(() => {
                    this.setState(null, false);
                    resolve()
                });
            })
            // return wait.then(() => this.setState());
    }

    sync() {
        const hotspot2AnyHotspotSyncIDFunc = (id) => {
            if (id === '') return;
            if (this.leftScene.currentSceneInfo.hotspots[id] !== undefined)
                this.hotspot2AnyHotspot(this.leftScene.currentSceneInfo.hotspots[id][id])
            else
                this.hotspot2AnyHotspot(this.rightScene.currentSceneInfo.hotspots[id][id])
        }
        const top2HotspotSyncIDFunc = (id) => {
            if (id === '') return;
            if (this.leftScene.currentSceneInfo.hotspots[id] !== undefined)
                this.top2HotSpot(this.leftScene.currentSceneInfo.hotspots[id][id])
            else
                this.top2HotSpot(this.rightScene.currentSceneInfo.hotspots[id][id])
        }

        this.viewBtnFuncs = this.initViewBtnFuncs(STATE)
        this.creatBtnFuncs(top2HotspotSyncIDFunc, hotspot2AnyHotspotSyncIDFunc)
    }

    creatBtnFuncs(top2HotSpotBtn, hotspot2AnyHotspotBtn) {
        createViewBtn(
            this.viewBtnFuncs,
            this.GoToLastDownViewPos,
            this.transition2Perspective,
            () => {},
            top2HotSpotBtn,
            STATE.FLOORPLAN
        );
        createViewBtn(
            this.viewBtnFuncs,
            this.GoToLastDownViewPos,
            () => {},
            this.GoOrthographicsView,
            top2HotSpotBtn,
            STATE.TOPVIEW
        );
        createViewBtn(
            this.viewBtnFuncs,
            () => {},
            this.BackToStartPos,
            this.GoOrthographicsView,
            hotspot2AnyHotspotBtn,
            STATE.FPVIEW
        );
    }

    GoOrthographicsView = () => {
        this.setState(STATE.FLOORPLAN);

        let wait = []
        wait.push(this.leftScene.movingController.GoOrthographicsView());
        wait.push(this.rightScene.movingController.GoOrthographicsView());

        Promise.all(wait).then(() => {
            this.setState(STATE.FLOORPLAN, false);
        });
    }

    transition2Perspective = () => {
        this.setState(STATE.FPVIEW);

        let wait = []
        wait.push(this.leftScene.movingController.transition2Perspective());
        wait.push(this.rightScene.movingController.transition2Perspective());

        Promise.all(wait).then(() => {
            this.setState(STATE.FPVIEW, false);
        });
    }

    top2HotSpot = (hotspot) => {
        const { viewButton } = store.getState();
        let srcIsOthographicCamera = viewButton.viewState === STATE.FLOORPLAN

        this.setState(STATE.FPVIEW);

        let leftHotspot = getCorespondHotspot(hotspot, this.leftScene.index, this.sceneInfos);
        let rightHotspot = getCorespondHotspot(hotspot, this.rightScene.index, this.sceneInfos);

        let wait = []
        wait.push(this.leftScene.movingController.top2HotSpot(leftHotspot, srcIsOthographicCamera));
        wait.push(this.rightScene.movingController.top2HotSpot(rightHotspot, srcIsOthographicCamera));

        Promise.all(wait).then(() => {
            this.setState(STATE.FPVIEW, false);
        });
    }

    hotspot2Hotspot = (hotspot, throughWallsetting = { enable: false }) => {
        this.setState(STATE.FPVIEW);

        let leftHotspot = getCorespondHotspot(hotspot, this.leftScene.index, this.sceneInfos);
        let rightHotspot = getCorespondHotspot(hotspot, this.rightScene.index, this.sceneInfos);

        let wait = []
        wait.push(this.leftScene.movingController.hotspot2Hotspot(leftHotspot, throughWallsetting));
        wait.push(this.rightScene.movingController.hotspot2Hotspot(rightHotspot, throughWallsetting));

        Promise.all(wait).then(() => {
            this.leftScene.updateRuler();
            this.rightScene.updateRuler();
            this.setState(STATE.FPVIEW, false);
        });
    }

    BackToStartPos = () => {
        this.setState(STATE.TOPVIEW);

        let wait = []
        wait.push(this.leftScene.movingController.BackToStartPos());
        wait.push(this.rightScene.movingController.BackToStartPos());

        Promise.all(wait).then(() => {
            this.setState(STATE.TOPVIEW, false);
        });
    }

    hotspot2AnyHotspot = (hotspot) => {
        this.setState(STATE.FPVIEW);

        let leftHotspot = getCorespondHotspot(hotspot, this.leftScene.index, this.sceneInfos);
        let rightHotspot = getCorespondHotspot(hotspot, this.rightScene.index, this.sceneInfos);

        let wait = []
        wait.push(this.leftScene.movingController.hotspot2AnyHotspot(leftHotspot));
        wait.push(this.rightScene.movingController.hotspot2AnyHotspot(rightHotspot));

        Promise.all(wait).then(() => {
            this.setState(STATE.FPVIEW, false);
        });
    }

    GoToLastDownViewPos = () => {
        const { viewButton } = store.getState();
        let srcIsOthographicCamera = viewButton.viewState === STATE.FLOORPLAN

        this.setState(STATE.FPVIEW);

        let wait = []
        wait.push(this.leftScene.movingController.GoToLastDownViewPos(srcIsOthographicCamera));
        wait.push(this.rightScene.movingController.GoToLastDownViewPos(srcIsOthographicCamera));

        Promise.all(wait).then(() => {
            this.setState(STATE.FPVIEW, false);
        });
    }
}

const SynchronizeController = new TwoSceneSynchronizeController();
export {
    STATE,
    SynchronizeController
}