import React, { Component } from 'react';
import store from 'store';
import qrcode from 'qrcode-generator/qrcode.js';
import APIService from 'services/cloudApi.js';
import cross from 'asset/image/cross.svg';
import facebookPng from 'asset/image/facebook.png';
import twitterPng from 'asset/image/twitter.png';
import './style.scss';
import { Trans } from 'react-i18next';

export default class QRCodeBoard extends Component {
  constructor(props) {
    super(props);
    this.qrcodeImg = null;
  }
  render() {
    const { parent } = this.props;
    const shareLink = window.location.href;
    return (
      <div className='qrcode-board'>
        <div className='qrcode-board-panel'>
          <div className='qrcode-close-btn' onClick={() => parent.triggerQRCodeBoard()}>
            <img src={cross} alt="close" className='qrcode-close-btn-img' />
          </div>
          <div className='qrcode-board-tilte'>
            <span><Trans>Share</Trans></span>
          </div>
          <div className='qrcode-board-contain'>
          <div className='qrcode-container'>
            <img
              className='qrcode-img'
              ref={ref => (this.qrcodeImg = ref)}
              alt="qrcode showing in here"
            />
          </div>
          <div className='share-container'>
            <div className='share-fb'>
              <div class="fb-share-button" 
                data-href={shareLink}
                data-layout="button" data-size="large">
                  <a target="_blank" 
                    href={`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`}
                    class="fb-xfbml-parse-ignore">
                      <img src={facebookPng} alt='fb-share'/>
                      <div>Facebook</div>
                  </a>
                </div>
            </div>
            <div className='share-twitter'>
              <a class="twitter-share-button"
                target="_blank" 
                href={`https://twitter.com/share?url=${encodeURIComponent(shareLink)}`}>
                <img src={twitterPng} alt='twitter-share'/>
                <div>Twitter</div>
              </a>
            </div>
          </div>
        </div>
        </div>
      </div>
    );
  }

  async componentDidMount() {
    var typeNumber = 4;
    var errorCorrectionLevel = 'L';
    var qr = qrcode(typeNumber, errorCorrectionLevel);

    const { id } = store.getState();
    // let qrUrl = await APIService.fetchQRCodeUrl(id);
    let qrUrl = window.location.href;
    console.log(qrUrl);
    qr.addData(qrUrl);
    // console.log(qr);
    qr.make();
    let src = qr.createDataURL();
    this.qrcodeImg.src = src;
  }
}
