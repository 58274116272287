export default {
  SET_BUILDING: 'SET_BUILDING',
  SET_ID: 'SET_ID',
  // SET_DOLLHOUSE_CUBEMAP: 'SET_DOLLHOUSE_CUBEMAP',
  // SET_ROOM_MODEL: 'SET_ROOM_MODEL',
  // SET_DOOR_MODEL: 'SET_DOOR_MODEL',
  SET_VIEWBTN: 'SET_VIEWBTN',
  SET_VIEW_VAR: 'SET_VIEW_VAR',
  // SET_HIERARCHY: 'SET_HIERARCHY',
  // SET_LABELS: 'SET_LABELS',
  // SET_BUILDINGID: 'SET_BUILDINGID',
  // SET_FLOORSTYLE: 'SET_FLOORSTYLE',
  // SET_CUBEMAP_URLS: 'SET_CUBEMAP_URLS',
  // SET_CUBEMAP_URLS_1X6: 'SET_CUBEMAP_URLS_1X6',
  // SET_PANORAMA_IMAGES: 'SET_PANORAMA_IMAGES',
  SET_CURRENT_FLOORID: 'SET_CURRENT_FLOORID',
  SET_QUERY_STRING: 'SET_QUERY_STRING'
}
