import anime from 'animejs/lib/anime.es';
import * as THREE from 'three';
import RenderOrder from 'services/3dviewer/object-manager/mat-manager';

function fovEasing(t) {
  return t ** 2;
}
function posEasing(t) {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

function dollHouseEasing(t) {
  return 1 + (t - 1) ** 5;
}

function rotEasing(t) {
  return t < 0.5 ? 16 * t ** 5 : 1 + 16 * (t - 1) ** 5;
}

const duration = 1500;

const clip = (cameraControls, destinationHotSpot, ObjectManager) => {
  const { roomGroup, rooms, doors } = ObjectManager.getAll();
  const { mainRoomId } = destinationHotSpot;

  rooms[mainRoomId].mesh.renderOrder = RenderOrder.fadeOut;
  doors[mainRoomId].mesh.renderOrder = RenderOrder.fadeOut;

  cameraControls.setGoinglimit();

  const hotSpotPos = new THREE.Vector3(0, 0, 0);
  destinationHotSpot.mesh.updateWorldMatrix(true, true);
  destinationHotSpot.mesh.getWorldPosition(hotSpotPos);

  const srcPosition = new THREE.Vector3(
    hotSpotPos.x,
    hotSpotPos.y + destinationHotSpot.cameraHeight * roomGroup.scale.y,
    hotSpotPos.z
  );
  const dstPosition = new THREE.Vector3(
    cameraControls.getCamerPosition().x,
    1,
    cameraControls.getCamerPosition().z
  );

  const srcFov = (cameraControls.defaultFov / 180) * Math.PI;
  const dstFov = cameraControls.fov;

  const srcPolarAngle = cameraControls.polarAngle;
  const dstPolarAngle = Math.PI / 2;

  // 控anime.js開始動畫
  const timeline = anime.timeline({
    autoplay: false,
    duration,
  });

  // position
  timeline.add(
    {
      update(anim) {
        // reverse hotspot-> orthographics
        const percentage = 1 - anim.progress / 100;
        const fovPercentage = fovEasing(percentage);
        const posEasingPercentage = posEasing(percentage);

        // fix fov position;
        const newPosition = new THREE.Vector3().lerpVectors(
          srcPosition,
          dstPosition,
          posEasingPercentage
        );

        // transition fov position;
        const calFov = (1 - fovPercentage) * srcFov + fovPercentage * dstFov;

        // recalculate y
        const deserveScreenHeight =
          Math.tan(((cameraControls.defaultFov / 180) * Math.PI) / 2) *
          newPosition.y *
          2;
        const distance = (1 / Math.tan(calFov / 2)) * (deserveScreenHeight / 2);
        const camControl = cameraControls;
        camControl.fov = calFov;
        cameraControls.setCamerPos(
          new THREE.Vector3(newPosition.x, distance, newPosition.z),
          true
        );
      },
    },
    0
  );

  timeline.add(
    {
      targets: cameraControls,
      polarAngle: [srcPolarAngle, dstPolarAngle],
      easing() {
        return rotEasing;
      },
    },
    200
  );

  // dollhouseFade
  timeline.add(
    {
      update(anim) {
        const fadeEasingPercentage = dollHouseEasing(1 - anim.progress / 100);
        ObjectManager.setAllMeshOpacity(fadeEasingPercentage);
      },
    },
    0
  );

  timeline.play();

  timeline.finished.then(() => {
    const disMainRoomId = destinationHotSpot.mainRoomId;
    rooms[disMainRoomId].mesh.renderOrder = RenderOrder.room;
    doors[disMainRoomId].mesh.renderOrder = RenderOrder.door;

    ObjectManager.setAllHotspotOpacity(0.4);
    cameraControls.setFirstpersonViewLimit();
  });

  return timeline.finished;
};

export default clip;
