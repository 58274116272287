export default `
varying mediump vec3 modelPosition;

uniform sampler2D tCube;
uniform lowp float opacity;
uniform mediump float rotation;
uniform float x;
uniform float z;
uniform float y;

void cubemap(vec3 r, out float texId, out vec2 st) {
   mediump vec3 uvw;
   lowp vec3 absr = abs(r);

   if (absr.x > absr.y && absr.x > absr.z) {
     // x major
     lowp float negx = step(r.x, 0.0);
     uvw = vec3(r.zy, absr.x) * vec3(mix(-1.0, 1.0, negx), -1, 1);
     texId = negx;
   } else if (absr.y > absr.z) {
     // y major
     lowp float negy = step(r.y, 0.0);
     uvw = vec3(r.xz, absr.y) * vec3(1.0, mix(1.0, -1.0, negy), 1.0);
     texId = 2.0 + negy;
   } else {
     // z major
     lowp float negz = step(r.z, 0.0);
     uvw = vec3(r.xy, absr.z) * vec3(mix(1.0, -1.0, negz), -1, 1);
     texId = 4.0 + negz;
   }
   st = vec2(uvw.xy / uvw.z + 1.) * .5;
}

mediump vec4 texCubemap(vec3 uvw) {
  lowp float texId;
  mediump vec2 st;

  // calc textureId depends on direction and get the UV coordinate
  cubemap(uvw, texId, st);

  //return color
  lowp float order;
  if (texId > 4.0) order = 2.0;
  else if (texId > 3.0) order = 4.0;
  else if (texId > 2.0) order = 1.0;
  else if (texId > 1.0) order = 0.0;
  else if (texId > 0.0) order = 5.0;
  else order = 3.0;

  float shrink = 0.999;
  return texture2D(tCube, vec2(st.x*shrink, (st.y*shrink + order) / 6.0));
}
  
void main() {
  mediump vec3 point = modelPosition;
  point.x = point.x - x;
  point.z = point.z + z;
  
  mediump mat4 rotationMat = mat4(
    cos(rotation), 0.0, -sin(rotation), 0.0,
    0.0, 1.0, 0.0, 0.0,
    sin(rotation), 0.0, cos(rotation), 0.0,
    0.0, 0.0, 0.0, 1.0
  );

  mediump vec3 modelVertex = (vec4(point, 1.0) * rotationMat).xyz;

  lowp vec4 color = texCubemap(normalize(modelVertex));
  gl_FragColor = vec4(color.xyz, opacity);
}
`;
