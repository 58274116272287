import fragmentShader from './fragmentShader';
import vertexShader from './vertexShader';

function CubemapShader1x6() {
  this.fragmentShader = fragmentShader;
  this.vertexShader = vertexShader;
  this.uniforms = {
    tCube: { value: null },
    opacity: { value: 1 },
    rotation: { value: 0 },
    x: { value: 0 },
    y: { value: 0 },
    z: { value: 0 },
  };
}

export default CubemapShader1x6;
