import * as THREE from 'three';
import * as matManager from './mat-manager';

const { RenderOrder, lineWidth, lineMat } = matManager;
export default function createWallMesh(start, end, group) {
  const dir = new THREE.Vector3().subVectors(start, end);
  dir.y = 0;
  if (dir.length() === 0) return;
  let angle = new THREE.Vector3(1, 0, 0).angleTo(dir);
  if (dir.z < 0) {
    angle = 2 * Math.PI - angle;
  }
  // create line mesh
  const line = new THREE.Mesh(
    new THREE.PlaneGeometry(dir.length() + lineWidth, lineWidth),
    lineMat
  );

  // set line position
  const center = new THREE.Vector3().addVectors(start, end).multiplyScalar(0.5);
  line.position.set(center.x, center.z, 0);
  line.rotation.z = angle;
  line.renderOrder = RenderOrder.wall;
  group.add(line);
}
