import React, { Component } from 'react';
import store from 'store';
import StoreGetters from 'store/store-getters';
import { STATE } from 'services/3dviewer/moving-controls/synchronizeController';

import './css/roomtypetag.scss';

export default class RoomTag extends Component {
  constructor() {
    super();
    this.state = {
      bottom: '50%',
      right: '50%',
      display: '',
      RWD: 'PC',
    };
    this.roomtype = '';
    this.onClick = () => {};
    this.updatePosition = this.updatePosition.bind(this);
    this.handleRWD = this.handleRWD.bind(this);
  }

  componentDidMount() {
    const { hierarchy } = StoreGetters.getCurrentRenderFloorData();

    const { room, scene } = this.props;
    const { mainRoomId, mesh } = room;
    const sceneIndex = scene.index;

    const roomDict = hierarchy[sceneIndex];
    console.log(roomDict.panoramas[mainRoomId])
    this.roomtype = roomDict.panoramas[mainRoomId].info.type;
    const self = this;
    const setPos = () => {
      const { scale, offset } = this.props;
      const sreenCoordinate = room.getTagScreenPosition(
        scene.viewerCameraController
      );
      if (
        sreenCoordinate.x > 0 &&
        sreenCoordinate.x < 1 &&
        sreenCoordinate.y > 0 &&
        sreenCoordinate.y < 1 &&
        sreenCoordinate.z < 1.0 &&
        sreenCoordinate.z > -1.0
      ) {
        if(this.state.RWD === "PC"){
          const screenX = (1 - sreenCoordinate.x) * scale + offset;
          const screenY = (1 - sreenCoordinate.y) * 100;
          self.updatePosition(screenX, screenY);
        }else{
          const screenX = (1 - sreenCoordinate.x) * 100;
          const screenY = (1 - sreenCoordinate.y) * scale + offset;
          self.updatePosition(screenX, screenY);
        }

        
      } else {
        self.hide();
      }
    };
    mesh.onAfterRender = setPos;

    const setVisible = () => {
      const { viewButton } = store.getState();
      const { viewState } = viewButton;
      const { isShowing } = this.props;

      if (isShowing === true && viewState !== STATE.FPVIEW) {
        room.tagVisible = true;
        mesh.onAfterRender = setPos;
      } else {
        room.tagVisible = false;
        mesh.onAfterRender = () => {};
        self.hide();
      }
      self.forceUpdate();
    };

    setVisible();

    this.onClick = () => {
      const { viewButton } = store.getState();
      const { goDependOnState } = viewButton;
      goDependOnState(mainRoomId);
    };

    this.handleRWD()
    window.addEventListener('resize', this.handleRWD);

    this.unsubscribe = store.subscribe(setVisible);
  }

  // setVisible = () => {
  //   const { viewButton } = store.getState();
  //   const { viewState } = viewButton;

  //   const { isShowing } = this.props;
  //   if (isShowing && viewState !== STATE.FPVIEW) {
  //     room.tagVisible = false;
  //     mesh.onAfterRender = () => {};
  //     self.hide();
  //   } else {
  //     room.tagVisible = true;
  //     mesh.onAfterRender = setPos;
  //   }
  //   this.forceUpdate();
  // };

  handleRWD() {
    if(window.innerWidth < 1366)
    {
      this.setState({
        RWD: 'Mobile',
      })
    }
    else
    {
      this.setState({
        RWD: 'PC'
      })
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }
  updatePosition(left, top) {
    this.setState({
      right: `${left.toString()}%`,
      bottom: `${top.toString()}%`,
      display: '',
    });
  }

  hide() {
    this.setState({
      display: 'none',
    });
  }

  render() {
    return (
      <button className="roomTag" style={this.state} onClick={this.onClick}>
        {this.roomtype}
      </button>
    );
  }
}
