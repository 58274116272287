import React from 'react'
import './svg-style.scss';

const SvgRuler =({func={},isOpenRuler=false})=>{
    return (
        <>
            <div className='svg-ruler' onClick={func}>
                <svg viewBox="0 0 27 30" fill={isOpenRuler?"rgba(237,12,116,1)":"none"} xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.67574 1H1.00006V29H25.973V22.9459H6.67574V1Z" stroke="white" stroke-width="1.2"/>
                    <line x1="1.37842" y1="5.41895" x2="4.40545" y2="5.41895" stroke="white"/>
                    <line x1="1.37842" y1="8.4458" x2="3.64869" y2="8.4458" stroke="white"/>
                    <line x1="1.37842" y1="11.4731" x2="4.40545" y2="11.4731" stroke="white"/>
                    <line x1="1.37842" y1="14.5" x2="3.64869" y2="14.5" stroke="white"/>
                    <line x1="1.37842" y1="17.5269" x2="4.40545" y2="17.5269" stroke="white"/>
                    <line x1="1.37842" y1="20.5542" x2="3.64869" y2="20.5542" stroke="white"/>
                    <line x1="1.37842" y1="23.5811" x2="4.40545" y2="23.5811" stroke="white"/>
                    <line x1="8.44598" y1="28.6216" x2="8.44598" y2="25.5946" stroke="white"/>
                    <line x1="1.37842" y1="26.6079" x2="3.64869" y2="26.6079" stroke="white"/>
                    <line x1="5.66223" y1="26.3516" x2="5.66223" y2="28.6218" stroke="white"/>
                    <line x1="14.5001" y1="28.6216" x2="14.5001" y2="25.5946" stroke="white"/>
                    <line x1="11.7162" y1="26.3516" x2="11.7162" y2="28.6218" stroke="white"/>
                    <line x1="20.5541" y1="28.6216" x2="20.5541" y2="25.5946" stroke="white"/>
                    <line x1="17.7703" y1="26.3516" x2="17.7703" y2="28.6218" stroke="white"/>
                    <circle cx="23.3244" cy="26.3514" r="0.913514" stroke="white" stroke-width="1.2"/>
                </svg>
            </div>
        </>
    )
}

export default SvgRuler