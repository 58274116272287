import React, { Component } from 'react';
import { DROPDOWN } from '../config';
import Dropdown from './dropdown';
// import store from 'store';

export default class FloorSelectionList extends Component {
    constructor(props){
        super(props);
        let topOffset = 20;
        let leftOffset = 0;
        if(this.props.RWD === "Mobile")
        {
            topOffset = 48;
            leftOffset = 0;
        }
        this.state = {
            mode: DROPDOWN.CLOSE,
            RWD: this.props.RWD,
            crntIndex: this.props.currentFloor,
            startIndex: 0,
            showLength: 5,
            indexInterval: [0, 1, 2, 3, 4],
            // topOffset: topOffset,
            leftOffset: leftOffset,
            bottomOffset: 0,
            buttonSize: this.props.RWD === "PC" ? [150, 50] : [100, 40],
            selectedBgColor: [85, 85, 85, 0.6],
            selectedTextColor: [237,12,116, 1.0],
            options: this.props.options,
        };
        this.updateList = this.updateList.bind(this);
    }

    updateList(options)
    {
        this.setState({
            options: options
        })
        this.swiper.updateList(options);
    }

    closeList()
    {
        this.swiper.handleClick("close");
    }

    handleClick(value, id=null) {
        console.log("Select floor: " + value);
        if(this.state.crntIndex != value)
        {
            this.props.parent.changeFloor(value);
        }
        this.setState({
            crntIndex: value
        })
    }

    render() {
        return <Dropdown info={this.state} parent={this} ref={ref => {this.swiper = ref}}/>
    }
}
