import * as THREE from 'three';

// arrange RenderOrder
const RenderOrder = {
  wall: 2,
  area: 0,
  door: 3,
  holeOnWall: 1,
};

// wall
const lineMat = new THREE.MeshBasicMaterial({
  color: 0x000000,
  transparent: true,
});
const lineWidth = 20;

// door
const doorThickness = lineWidth * 0.1;
const doorMat = new THREE.MeshBasicMaterial({
  color: 0x000000,
  transparent: true,
  depthTest: false,
  opacity: 1,
});
const hideWallMat = new THREE.MeshBasicMaterial({
  color: 0x000000,
  transparent: true,
  opacity: 0,
});
const arcMaterial = new THREE.LineBasicMaterial({
  color: 0x000000,
  transparent: true,
  depthTest: false,
  opacity: 1,
});

// area
const areaMat = new THREE.MeshBasicMaterial({
  color: 0xffffff,
  transparent: true,
  opacity: 1,
  depthWrite: false,
});

export {
  RenderOrder,
  lineMat,
  lineWidth,
  doorThickness,
  doorMat,
  hideWallMat,
  arcMaterial,
  areaMat,
};
