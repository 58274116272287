import anime from 'animejs/lib/anime.es';

const duration = 500;

const clip = (fadeMode, ObjectManager) => {
  let srcAlpha = 0.0;
  let dstAlpha = 1.0;

  if (fadeMode === 'fadeIn') {
    srcAlpha = 0.0;
    dstAlpha = 1.0;
  } else if (fadeMode === 'fadeOut') {
    srcAlpha = 1.0;
    dstAlpha = 0.0;
  }

  const timeline = anime.timeline({
    autoplay: false,
    duration,
  });

  timeline.add(
    {
      update: function animate(anim) {
        const percentage = anim.progress / 100;

        ObjectManager.setAllBoundingAlpha(
          srcAlpha * (1 - percentage) + percentage * dstAlpha
        );
      },
    },
    0
  );

  timeline.play();

  return timeline.finished;
};

export default clip;
