import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import ar from "./asset/i18n/ar.json";
import en from "./asset/i18n/en.json";
import de from "./asset/i18n/de.json";
import fr from "./asset/i18n/fr.json";
import ja from "./asset/i18n/ja.json";
import nl from "./asset/i18n/nl.json";
import zhCN from "./asset/i18n/zh-CN.json";
import zhTW from "./asset/i18n/zh-TW.json";

// the translations
const resources = {
  ar: {
    translation: ar
  },
  en: {
    translation: en
  },
  de: {
    translation: de
  },
  fr: {
    translation: fr
  },
  ja: {
    translation: ja
  },
  nl: {
    translation: nl
  },
  "zh-CN": {
    translation: zhCN
  },
  "zh-TW": {
    translation: zhTW
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;