import anime from 'animejs/lib/anime.es';
import { FACTOR } from 'services/3dviewer/camera/cameraControls';

const duration = 2000;

const clip = cameraControls => {
  const srcPolarAngle = cameraControls.polarAngle;
  const dstPolarAngle = Math.PI / 3.5;

  const srcAziAngle = cameraControls.azimuthAngle;
  const dstAziAngle = cameraControls.azimuthAngle - FACTOR.INIT.ANIMATIONANGLE;

  // 控anime.js開始動畫
  const timeline = anime.timeline({
    autoplay: false,
    duration,
  });

  timeline.add(
    {
      targets: cameraControls,
      polarAngle: [srcPolarAngle, dstPolarAngle],
      easing: 'easeOutCubic',
    },
    500
  );

  timeline.add(
    {
      targets: cameraControls,
      azimuthAngle: [srcAziAngle, dstAziAngle],
      easing: 'easeOutCubic',
    },
    500
  );

  timeline.play();

  return timeline.finished;
};

export default clip;
