import React, { Component } from 'react';
import {DROPDOWN} from '../config';

export default class Dropdown extends Component {
    constructor(props){
        super(props);
        let info = this.props.info;
        this.state = {
            mode: DROPDOWN.CLOSE,
            RWD: info.RWD,
            crntIndex: info.crntIndex,
            startIndex: info.startIndex,
            showLength: info.showLength,
            indexInterval: info.indexInterval,
            topOffset: info.topOffset,
            leftOffset: info.leftOffset,
            buttonSize: info.buttonSize,
            selectedBgColor: info.selectedBgColor,
            selectedTextColor: info.selectedTextColor,
            options: info.options,
            parent: this.props.parent
        };
        this.updateList = this.updateList.bind(this);
    }

    handleClick = (value, id=null) => {
        if(this.state.mode === DROPDOWN.CLOSE && value === "open")
        {
            this.setState({
                mode: DROPDOWN.OPEN
             })
         
        }
        else
        {
            if(value === "close")
            {
                let newIndex = this.state.crntIndex;
                this.state.options.forEach((e, key) => {       
                    if(e.id === id)
                    {
                        newIndex = key;
                    }
                })

                this.setState({
                    mode: DROPDOWN.CLOSE,
                    crntIndex: newIndex
                })
            }
            else if(value === "up" || value === "down")
            {
                let startIndex = this.state.startIndex;
                let showLength = this.state.showLength;
                let optionLength = this.state.options.length;
                if(value === "up")
                    startIndex = (startIndex + 1) % optionLength;
                else
                    startIndex = ((startIndex - 1) + optionLength) % optionLength;
                let indexInterval = [];
                for(let i = 0; i < showLength; i++)
                {
                    let index = (startIndex + i) % optionLength;
                    indexInterval.push(index);
                }
                this.setState({
                    startIndex: startIndex,
                    indexInterval: indexInterval
                })
            }
            else
            {
                let crntIndex = this.state.crntIndex;
                if(value !== crntIndex)
                {
                    this.setState({
                        crntIndex: value
                    })

                    this.state.parent.handleClick(value, id);
                }
                this.setState({
                    mode: DROPDOWN.CLOSE
                })
            }
        }
    }

    updateList(options)
    {
        this.setState({
            options: options
        })
    }

    render() {
        let optionLength = Math.min(this.state.options.length, this.state.showLength);
        let downButtonPos = this.state.topOffset + (optionLength + 1) * this.state.buttonSize[1];
        let selectedBgColor = this.state.selectedBgColor;
        let selectedTextColor = this.state.selectedTextColor;
        let buttonSize = this.state.buttonSize;
        if(optionLength === 0)
        {
            return null;
        }

        return(
            <div>
                {this.state.RWD === "Mobile" && (
                    <button className="show-dropdown-close-mobile-bt" 
                        style={{top: '0px', left: this.state.leftOffset+'px', width: buttonSize[0], height: buttonSize[1]}}
                        onClick={(event) => {
                            if(this.state.mode === DROPDOWN.CLOSE)
                                this.handleClick("open"); 
                            else
                                this.handleClick("close");
                            event.stopPropagation();
                        }}
                    >
                        <span className="dropdown-close-value">{this.state.options[this.state.crntIndex].label.length > 2 ? 
                                                                this.state.options[this.state.crntIndex].label.substr(0, 2) + "..." : 
                                                                this.state.options[this.state.crntIndex].label}</span>
                        <i className="dropdown-close-down"></i>
                    </button>
                )}
                {this.state.mode === DROPDOWN.CLOSE && this.state.RWD === "PC" && (
                    <button className="show-dropdown-close" 
                        style={{top: this.state.topOffset+'px', left: this.state.leftOffset+'px', width: buttonSize[0], height: buttonSize[1]}}
                        onClick={(event) => {this.handleClick("open"); event.stopPropagation();}}
                    >
                        <span className="dropdown-close-value">{this.state.options[this.state.crntIndex].label.length > 5 ?
                                                                this.state.options[this.state.crntIndex].label.substr(0, 5) + "..." :
                                                                this.state.options[this.state.crntIndex].label}</span>
                        <i className="dropdown-close-down"></i>
                    </button>
                )}
                {this.state.mode === DROPDOWN.OPEN && (
                    <div>
                        <button className="show-dropdown-open"
                                style={{top: this.state.topOffset+'px', left: this.state.leftOffset+'px', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, width: buttonSize[0], height: buttonSize[1]}}
                                onClick={(event) => {this.handleClick("up"); event.stopPropagation();}}
                        >
                            <i className="dropdown-open-up"></i>
                        </button>

                        {this.state.options.map((e, key) => {
                            let crntIndex = this.state.crntIndex;
                            let indexInterval = this.state.indexInterval;
                            if(indexInterval.indexOf(key) >= 0)
                            {
                                let offset = this.state.topOffset + (indexInterval.indexOf(key) + 1) * this.state.buttonSize[1];
                                if(key === crntIndex)
                                {
                                    return <button key={key} 
                                                className = "dropdown-open-value" 
                                                id = {e.id}
                                                style = {{top: offset+'px', 
                                                        left: this.state.leftOffset+'px',
                                                        backgroundColor: 'rgba('+selectedBgColor+')',
                                                        color: 'rgba('+selectedTextColor+')',
                                                        width: buttonSize[0],
                                                        height: buttonSize[1]
                                                }}
                                                onClick = {(event) => {this.handleClick(key, e.id); event.stopPropagation();}}
                                            >
                                                {(this.state.RWD === "PC" && e.label.length > 5) ?
                                                    e.label.substr(0, 5) + "..." :
                                                    (this.state.RWD === "Mobile" && e.label.length > 3) ?
                                                    e.label.substr(0, 3) + "..." :
                                                    e.label 
                                                }
                                            </button> 
                                }
                                else
                                {
                                    return <button key = {key} 
                                                className = "dropdown-open-value" 
                                                id = {e.id}
                                                style = {{top: offset+'px', left: this.state.leftOffset+'px', width: buttonSize[0], height: buttonSize[1]}}
                                                onClick = {(event) => {this.handleClick(key, e.id); event.stopPropagation();}}
                                            >
                                                {(this.state.RWD === "PC" && e.label.length > 5) ?
                                                    e.label.substr(0, 5) + "..." :
                                                    (this.state.RWD === "Mobile" && e.label.length > 3) ?
                                                    e.label.substr(0, 3) + "..." :
                                                    e.label 
                                                }
                                            </button> 
                                }
                            } else {
                                return ''
                            }
                        })}

                        <button className="show-dropdown-open" 
                                style={{top: downButtonPos+'px', left: this.state.leftOffset+'px', borderTopLeftRadius: 0, borderTopRightRadius: 0, width: buttonSize[0], height: buttonSize[1]}}
                                onClick={(event) => {this.handleClick("down"); event.stopPropagation();}}
                        >
                            <i className="dropdown-open-down"></i>
                        </button>
                    </div>
                )}
            </div>
        );
    }
}
