import * as THREE from 'three';
import Shaders from 'services/shader';

function RoomCarrier(iMesh, iMainroomId) {
    if (!(iMesh && iMainroomId)) {
        console.log('Mesh or Mainroom is undefined');
        return;
    }
    const manhattanRotation = 0;
    const textureId = null;
    let texture = null;
    const cameraHeight = null;
    const points = null;
    const mesh = iMesh;
    const mainRoomId = iMainroomId;
    const CubemapShader1x6 = new Shaders.CubemapShader1x6();
    const material = new THREE.ShaderMaterial({
        fragmentShader: CubemapShader1x6.fragmentShader,
        vertexShader: CubemapShader1x6.vertexShader,
        uniforms: CubemapShader1x6.uniforms,
        depthTest: true,
        transparent: true,
    });

    const tagGroup = new THREE.Group();

    const tagLineLong = 1;
    const tagLineGeo = new THREE.Geometry();
    tagLineGeo.vertices.push(new THREE.Vector3(0, 0, 0));
    tagLineGeo.vertices.push(new THREE.Vector3(0, tagLineLong, 0));
    const tagLineMat = new THREE.LineBasicMaterial({
        color: 0xffffff,
    });
    const tagLine = new THREE.Line(tagLineGeo, tagLineMat);
    tagGroup.add(tagLine);

    function setTexture(iTexture, position = new THREE.Vector3(), rotateY = 0) {
        this.textureId = iTexture.name;
        texture = iTexture;
        texture.generateMipmaps = false;
        texture.minFilter = THREE.NearestFilter;
        texture.magFilter = THREE.NearestFilter;
        texture.wrapS = THREE.ClampToEdgeWrapping;
        texture.wrapT = THREE.ClampToEdgeWrapping;
        material.uniforms.tCube.value = texture;
        material.uniforms.rotation.value = -THREE.Math.degToRad(
            rotateY + THREE.Math.radToDeg(this.manhattanRotation)
        );
        material.uniforms.x.value = position.x;
        material.uniforms.y.value = position.y;
        material.uniforms.z.value = position.z;
        mesh.material = material;
    }

    function setTagVisible(visible) {
        tagGroup.visible = visible;
    }

    function getIsTagVisible() {
        return tagGroup.visible;
    }

    function getOpacity() {
        return material.uniforms.opacity.value;
    }

    function setOpacity(opacity) {
        material.uniforms.opacity.value = opacity;
    }

    function setCameraHeight(height) {
        this.cameraHeight = height;
    }

    function setPoints(iPoints) {
        this.points = iPoints;
    }

    function setManhattanRotation(iManhattanRotation) {
        this.manhattanRotation = iManhattanRotation;
    }

    function destroy() {
        material.dispose();
        if (texture) texture.dispose();
    }

    function getTagScreenPosition(viewerCameraControls) {
        const position = new THREE.Vector3();
        const rot = new THREE.Quaternion();
        const scale = new THREE.Vector3();
        mesh.updateMatrixWorld();
        mesh.matrixWorld.decompose(position, rot, scale);
        position.y += tagLineLong * scale.y;
        position.project(viewerCameraControls.camera);
        position.x = position.x * 0.5 + 0.5;
        position.y = -(position.y * 0.5) + 0.5;
        return position;
    }
    /** public */
    // getters
    this.manhattanRotation = manhattanRotation;
    this.textureId = textureId;
    this.mesh = mesh;
    this.tagLine = tagLine;
    this.tagGroup = tagGroup;
    this.mainRoomId = mainRoomId;
    this.cameraHeight = cameraHeight;
    this.points = points;
    // methods
    this.setPoints = setPoints;
    this.setTexture = setTexture;
    this.destroy = destroy;
    this.setCameraHeight = setCameraHeight;
    this.setOpacity = setOpacity;
    this.setManhattanRotation = setManhattanRotation;
    this.getTagScreenPosition = getTagScreenPosition;

    Object.defineProperty(this, 'mesh', {
        value: mesh,
        enumerable: true,
    });
    Object.defineProperty(this, 'tagLine', {
        value: tagLine,
        enumerable: true,
    });
    Object.defineProperty(this, 'points', {
        value: this.points,
        enumerable: true,
    });
    Object.defineProperty(this, 'manhattanRotation', {
        value: this.manhattanRotation,
        enumerable: true,
    });
    Object.defineProperty(this, 'opacity', {
        get: getOpacity,
        set: setOpacity,
    });
    Object.defineProperty(mesh, 'mainRoomId', {
        value: this.mainRoomId,
        enumerable: true,
    });
    Object.defineProperty(mesh, 'objectType', {
        value: 'room',
        enumerable: true,
    });
    Object.defineProperty(this, 'cameraHeight', {
        value: this.cameraHeight,
        enumerable: true,
    });
    Object.defineProperty(this, 'tagVisible', {
        get: getIsTagVisible,
        set: setTagVisible,
    });
}

export default RoomCarrier;