import React from 'react'
import './svg-style.scss'
import { useRef } from "react";

const SvgFullscreen =()=>{
    const SvgFillRef = useRef(null);
    const white='#fff'
    const mainColor="rgba(237,12,116,1)"
    const toggleFullScreen = ()=>{
        var isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
            (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
            (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
            (document.msFullscreenElement && document.msFullscreenElement !== null);

        var docElm = document.documentElement;
        if (!isInFullScreen) {
            SvgFillRef.current.style.fill = mainColor
            if (docElm.requestFullscreen) {
                docElm.requestFullscreen();
            } else if (docElm.mozRequestFullScreen) {
                docElm.mozRequestFullScreen();
            } else if (docElm.webkitRequestFullScreen) {
                docElm.webkitRequestFullScreen();
            } else if (docElm.msRequestFullscreen) {
                docElm.msRequestFullscreen();
            }
        } else {
            SvgFillRef.current.style.fill = white
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }

    }
    
    return (
        <div className='svg-fullscreen' onClick={toggleFullScreen}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                <g fill="none" fill-rule="evenodd">
                <path d="M0 0h22.167v22.167H0z"></path>
                <path
                    fill='#fff'
                    ref={SvgFillRef}
                    className='svg-color'
                    d="M14.083 3l2.125 2.124-2.67 2.651 1.312 1.312 2.65-2.67
                    2.125 2.125V3h-5.542zM3 8.542l2.124-2.125 2.651 2.67 1.312-1.312-2.67-2.65L8.542
                    3H3v5.542zm5.542 11.083l-2.125-2.124 2.67-2.651-1.312-1.312-2.65 2.67L3
                    14.083v5.542h5.542zm11.083-5.542l-2.124 2.125-2.651-2.67-1.312 1.312
                    2.67 2.65-2.125 2.125h5.542v-5.542z">
                </path>
                </g>
            </svg>
        </div>
    )
}

export default SvgFullscreen