import React, { Component } from 'react';
// import { isMobile } from 'react-device-detect';
import FloorSelectionList from './floorSelectionList';
import RoomSelectionList from './roomSelectionList';
import MultiFloorDropdown from './multiFloorDropdown';
import store from 'store';
import actions from 'store/actions';
import {
  STATE,
  SynchronizeController,
} from 'services/3dviewer/moving-controls/synchronizeController';

export default class SelectionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buildingData: null,
      currentFloor: this.props.currentFloorIdx,
      currentRoom: 0,
      currentStyle: 0,
      hideFloor: true,
      hideRoom: true,
      hideMultiFloor: true,
      RWD: 'PC',
    };
    this.updateList = this.updateList.bind(this);
    this.closeList = this.closeList.bind(this);
    this.checkStatus = this.checkStatus.bind(this);
    this.viewChange = this.viewChange.bind(this);
    this.handleRWD = this.handleRWD.bind(this);
  }

  componentDidMount() {
    store.subscribe(this.viewChange); // add moniter changes from store and set listener
    this.viewChange();
    
    window.addEventListener('click', this.checkStatus);
    window.addEventListener('resize', this.handleRWD);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.checkStatus);
  }

  getFloorList() {
    let floorList = [];
    if (this.state.buildingData !== null && this.state.buildingData.length > 1) {
      floorList = [];
      for (let i = 0; i < this.state.buildingData.length; i++)
        floorList.push({ value: i, label: this.state.buildingData[i]["label"] })
    }
    return floorList;
  }

  getRoomList() {
    let roomList = [];
    if (this.state.buildingData !== null) {
      roomList = this.state.buildingData[this.state.currentFloor]["styles"][
        this.state.currentStyle
      ];
    }
    return roomList;
  }

  viewChange() {
    const { viewButton, viewVariable } = store.getState();
    if (viewButton !== null && viewVariable !== null) {
      const { viewState } = viewButton;
      if (!SynchronizeController.isMoving) {
        if (viewState === STATE.FPVIEW && this.state.hideRoom) {
          this.hideList(false);
          this.handleRWD();
        }
      }

      if (viewVariable.isCameraMoving) {
        this.setState({
          hideMultiFloor: true
        })
      }
      else {
        this.setState({
          hideMultiFloor: viewState === STATE.FPVIEW
        })
      }
    }
  }

  init(building) {
    let buildingData = [];
    // eslint-disable-next-line
    // for (const [k, v] of Object.entries(building)) {
    //   let hierarchy = v['hierarchy'];
    //   let styles = [];
    //   for (let i = 0; i < hierarchy.length; i++) {
    //     // parse room info
    //     let roomList = [];
    //     let index = 0;
    //     for (const [key, value] of Object.entries(hierarchy[i]['panoramas'])) {
    //       if (value['info']['isShowing'] === true) {
    //         roomList.push({
    //           value: index++,
    //           id: key,
    //           label: value['info']['type'],
    //         });
    //       }
    //     }
    //     styles.push(roomList);
    //   }
    //   buildingData.push(styles);
    // }
    const isPanoramaSort = new URL(window.location.href).searchParams.get('isSort');

    let floorList = building['floorList'];
    for (let i = 0; i < floorList.length; i++)
    {
      let id = floorList[i];
      let floor = building[id];

      let hierarchy = floor['hierarchy'];
      let styles = [];
      for (let j = 0; j < hierarchy.length; j++) {
        // parse room info
        let roomList = [];
        let index = 0;

        if (isPanoramaSort==='true') {
          //依照VR maker editor排序swiper 2D全景圖
          floor.panoramaSort[j].forEach((panosID) => {
            for (const [key, value] of Object.entries(hierarchy[j]['panoramas'])) {
              if (panosID.objectId === value['layout']['mainroom']) {
                roomList.push({
                  value: index++,
                  id: key,
                  label: value['info']['type'],
                });
              }
            }
          })
        } else {
          //依照2.5D editor排序swiper 2D全景圖
          for (const [key, value] of Object.entries(hierarchy[j]['panoramas'])) {
            if (value['info']['isShowing'] != false) {
              roomList.push({
                value: index++,
                id: key,
                label: value['info']['type'],
              });
            }
          }
        }

        styles.push(roomList);
      }
      let label = floor["name"];
      buildingData.push({"styles": styles, "label": label});
    }

    this.setState({
      buildingData: buildingData,
    });
  }

  checkStatus() {
    const { viewButton } = store.getState();
    const { viewState } = viewButton;
    if (viewState !== STATE.FPVIEW) {
      this.hideList(true);
      requestAnimationFrame(this.checkStatus);
    } else {
      if(this.props.parent.leftScene)
      {
        let currentPanoId = this.props.parent.leftScene.threeScene.getCurrentPanoId();
        if (currentPanoId === '') requestAnimationFrame(this.checkStatus);
        else {
          this.hideList(false);
        }
        this.closeList(currentPanoId);
      }
    }
  }

  handleRWD() {
    if(window.innerWidth < 1366)
    {
      this.setState({
        RWD: 'Mobile',
      })
    }
    else
    {
      this.setState({
        RWD: 'PC'
      })
    }
  }

  closeList(currentPanoId) {
    if (this.floorSelectionList !== null) this.floorSelectionList.closeList();
    if (this.roomSelectionList !== null)
      this.roomSelectionList.closeList(currentPanoId);
  }

  hideList(status) {
    this.setState({
      hideFloor: status,
      hideRoom: status,
    });

    if (status === false) this.updateList();
  }

  updateList() {
    let floorList = this.getFloorList();
    let roomList = this.getRoomList();
    if(this.floorSelectionList !== null)
      this.floorSelectionList.updateList(floorList);
    if(this.roomSelectionList !== null)
      this.roomSelectionList.updateList(roomList);
  }

  changeFloor(value) {
    console.log("Change floor.");
    this.props.parent.changeFloor(value);
  }

  render() {
    let floorList = this.getFloorList();
    let roomList = this.getRoomList();

    if(this.state.RWD === "PC")
    {
      return (
        <div>
          {this.state.buildingData !== null && !this.state.hideFloor && (
            <FloorSelectionList
              RWD={this.state.RWD}
              options={floorList}
              ref={ref => {
                this.floorSelectionList = ref;
              }}
              parent={this}
              currentFloor={this.state.currentFloor}
            />
          )}
          {this.state.buildingData !== null && !this.state.hideRoom && (
            <RoomSelectionList
              RWD={this.state.RWD}
              options={roomList}
              alignLeft={this.state.buildingData.length <= 1}
              ref={ref => {
                this.roomSelectionList = ref;
              }}
              parent={this}
              currentRoom={this.state.currentRoom}
            />
          )}
          {this.state.buildingData !== null && !this.state.hideMultiFloor && (
            <MultiFloorDropdown
              RWD={this.state.RWD}
              options={floorList}
              currentFloor={this.state.currentFloor}
              ref={ref => {
                this.multiFloorList = ref;
              }}
              parent={this}
            />
          )}
        </div>
      );
    }
    else if(this.state.RWD === "Mobile")
    {
      return (
        <div>
          <div >
            {this.state.buildingData !== null && !this.state.hideFloor && (
              <FloorSelectionList
                RWD={this.state.RWD}
                options={floorList}
                ref={ref => {
                  this.floorSelectionList = ref;
                }}
                parent={this}
                currentFloor={this.state.currentFloor}
              />
            )}
            {this.state.buildingData.length > 1 && !this.state.hideFloor && <i className="dropdown-close-mobile-frame"></i>}
            {this.state.buildingData !== null && !this.state.hideRoom && (
              <RoomSelectionList
                RWD={this.state.RWD}
                options={roomList}
                alignLeft={this.state.buildingData.length <= 1}
                ref={ref => {
                  this.roomSelectionList = ref;
                }}
                parent={this}
                currentRoom={this.state.currentRoom}
              />
            )}
          </div>
          {this.state.buildingData !== null && !this.state.hideMultiFloor && (
            <MultiFloorDropdown
              RWD={this.state.RWD}
              options={floorList}
              currentFloor={this.state.currentFloor}
              ref={ref => {
                this.multiFloorList = ref;
              }}
              parent={this}
            />
          )}
        </div>
      );
    }
  }
}
