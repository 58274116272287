import anime from 'animejs/lib/anime.es';
import * as THREE from 'three';
import RenderOrder from 'services/3dviewer/object-manager/mat-manager';

const duration = 1500;
const hotspotMinAlpha = 0.4;

function posEasing(t) {
  return t < 0.5 ? 8 * t ** 4 : 1 - 8 * (t - 1) ** 4;
}

function fixFovRotEasing(t) {
  let time = t;
  const func = () => {
    return t < 0.5 ? 16 * t ** 5 : 1 + 16 * (t - 1) ** 5;
  };
  if (t - 0.5 < 0) {
    time = 0;
  } else {
    time = (time - 0.5) * 2;
  }
  const value = func(time);
  return value > 1 ? 1 : value;
}

function rotEasing(t) {
  return t < 0.5 ? 16 * t ** 5 : 1 + 16 * (t - 1) ** 5;
}

function dollHouseEasing(t) {
  return 1 + (t - 1) ** 5;
}

function yDelta(t) {
  return Math.sin(t * Math.PI);
}

const clip = (cameraControls, destinationHotSpot, fixFov, ObjectManager) => {
  const { rooms, doors, roomGroup } = ObjectManager.getAll();
  rooms[destinationHotSpot.mainRoomId].mesh.renderOrder = RenderOrder.fadeOut;
  doors[destinationHotSpot.mainRoomId].mesh.renderOrder = RenderOrder.fadeOut;

  cameraControls.setGoinglimit();

  const srcPosition = cameraControls.getCamerPosition();
  const srcPolarAngle = cameraControls.polarAngle;

  const hotSpotPos = new THREE.Vector3(0, 0, 0);
  destinationHotSpot.mesh.updateWorldMatrix(true, true);
  destinationHotSpot.mesh.getWorldPosition(hotSpotPos);

  const dstPosition = new THREE.Vector3(
    hotSpotPos.x,
    hotSpotPos.y + destinationHotSpot.cameraHeight * roomGroup.scale.y,
    hotSpotPos.z
  );
  const dstPolarAngle = Math.PI / 2;

  // 控anime.js開始動畫
  const timeline = anime.timeline({
    autoplay: false,
    duration,
  });

  if (fixFov) {
    // position
    timeline.add(
      {
        targets: cameraControls,
        fov: [cameraControls.fov, (cameraControls.defaultFov / 180) * Math.PI],
        easing: 'easeInOutExpo',
      },
      0
    );
  }

  // position
  timeline.add(
    {
      update(anim) {
        const posEasingPercentage = posEasing(anim.progress / 100);

        const position = new THREE.Vector3().lerpVectors(
          srcPosition,
          dstPosition,
          posEasingPercentage
        );
        position.y -=
          yDelta(posEasingPercentage) *
          Math.abs(dstPosition.y - srcPosition.y) *
          0.2;

        cameraControls.setCamerPos(position, true);
      },
    },
    0
  );

  // polar angel rotation
  timeline.add(
    {
      targets: cameraControls,
      polarAngle: [srcPolarAngle, dstPolarAngle],
      easing() {
        return fixFov ? fixFovRotEasing : rotEasing;
      },
    },
    0
  );

  // dollhouseFade
  timeline.add(
    {
      update(anim) {
        const fadeEasingPercentage = dollHouseEasing(1 - anim.progress / 100);
        ObjectManager.setAllMeshOpacity(fadeEasingPercentage);
        // console.log(fadeEasingPercentage)
      },
    },
    0
  );

  timeline.play();

  timeline.finished.then(() => {
    rooms[destinationHotSpot.mainRoomId].mesh.renderOrder = RenderOrder.room;
    doors[destinationHotSpot.mainRoomId].mesh.renderOrder = RenderOrder.door;

    ObjectManager.setAllHotspotOpacity(hotspotMinAlpha);
    cameraControls.setFirstpersonViewLimit();
  });

  return timeline.finished;
};

export default clip;
