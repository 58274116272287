import store from 'store';
import actions from 'store/actions';
import StoreGetters from 'store/store-getters';

function createViewBtn(viewBtnFuncs, to3D, toTop, toOrtho, siwperClick, state) {
  viewBtnFuncs[state]['goDown'].push(to3D)
  viewBtnFuncs[state]['goUp'].push(toTop)
  viewBtnFuncs[state]['toOrthographics'].push(toOrtho)
  viewBtnFuncs[state]['goDependOnState'].push(siwperClick)
  viewBtnFuncs[state]['viewState'].push(state)
}

function updateViewBtn(viewBtnFuncs, state) {
  store.dispatch(
    actions.setViewButton({
      'goDown': () => {
        viewBtnFuncs[state]['goDown'].forEach(func => {
          func();
        })
      },
      'goUp': () => {
        viewBtnFuncs[state]['goUp'].forEach(func => {
          func();
        })
      },
      'toOrthographics': () => {
        viewBtnFuncs[state]['toOrthographics'].forEach(func => {
          func();
        })
      },
      'goDependOnState': (e) => {
        viewBtnFuncs[state]['goDependOnState'].forEach(func => {
          func(e);
        })
      },
      'viewState': viewBtnFuncs[state]['viewState'][0],
    })
  );
}

function findNearestHotSpotFromTwoBulding(movingFuncsLeft, movingFuncsRight, indexLeft, indexRight, sceneInfos) {
  return (hotspot) => {
    movingFuncsLeft(getCorespondHotspot(hotspot, indexLeft, sceneInfos))
    movingFuncsRight(getCorespondHotspot(hotspot, indexRight, sceneInfos))
  }
}

function getCorespondHotspot(hotspot, targetIndex, sceneInfos) {
  const { styles } = StoreGetters.getCurrentRenderFloorData();
  const targetPanos = styles[targetIndex].panoIds

  let panoIndex = -1
  for (let i = 0; i < styles.length; i++) {
    panoIndex = styles[i].panoIds.indexOf(hotspot.hotspotId)
    if (panoIndex !== -1) {
      break;
    }
  }

  let roomIndex = -1
  for (let i = 0; i < styles.length; i++) {
    roomIndex = styles[i].panoIds.indexOf(hotspot.mainRoomId)
    if (roomIndex !== -1) {
      break;
    }
  }
  const panoKey = targetPanos[panoIndex]
  const roomKey = targetPanos[roomIndex]

  // TODO: Duplicate data in 2 scenes
  return sceneInfos[targetIndex].hotspots[roomKey][panoKey]
}

export {
  createViewBtn,
  updateViewBtn,
  getCorespondHotspot,
  findNearestHotSpotFromTwoBulding
}