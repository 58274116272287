import * as THREE from 'three';
import fragmentShader from './fragmentShader';
import vertexShader from './vertexShader';

function CubemapShader() {
  this.fragmentShader = fragmentShader;
  this.vertexShader = vertexShader;
  this.uniforms = {
    tEquirect: { value: null },
    rotation: { value: new THREE.Matrix4().identity() },
    alpha: { value: 1 },
    x: { value: 0 },
    y: { value: 0 },
    z: { value: 0 },
  };
}

export default CubemapShader;
