import MouseControls from './mouse-control';
import MouseHintController from './mouse-hint-points-controls';
import CanvasEvents from './canvas-events';

function MouseEvents(viewerCameraController, movingController, canvas) {
  const mouseControls = new MouseControls(viewerCameraController);
  this.mouseHintController = new MouseHintController(viewerCameraController);
  const canvasEvents = new CanvasEvents(
    mouseControls,
    movingController,
    this.mouseHintController,
    viewerCameraController,
    canvas
  );

  function setHotspotDetect(
    allHotspotsMeshArray
  ) {
    this.mouseHintController.allHotspots = allHotspotsMeshArray;
  }

  function setCanvasEvent(
    allHotspotsMeshArray,
    allRoomsMeshArray,
    allDoorsMeshArray,
    hotspots
  ) {
    canvasEvents.registTargets(
      allHotspotsMeshArray,
      allRoomsMeshArray,
      allDoorsMeshArray,
      hotspots,
    )
  }
  this.setHotspotDetect = setHotspotDetect;
  this.setCanvasEvent = setCanvasEvent;
}

export default MouseEvents;
