import store from 'store/index';

export default {
  getCubemapUrl1x6(id, floorID = null) {
    if (!floorID) {
      floorID = store.getState().currentRenderFloorID;
    }
    return store.getState().building[floorID].cubemapUrls1x6[id];
  },
  getCubemapUrl(id, floorID = null) {
    if (!floorID) {
      floorID = store.getState().currentRenderFloorID;
    }
    return store.getState().building[floorID].cubemapUrls[id];
  },
  getCurrentRenderFloorData() {
    let floorID = store.getState().currentRenderFloorID;

    if (floorID) {
      return store.getState().building[floorID];
    } else {
      return store.getState().building;
    }
  },
  getCurrentRenderFloorStyles() {
    let floorID = store.getState().currentRenderFloorID;

    if (floorID) {
      return store.getState().building[floorID].styles;
    } else {
      return null;
    }
  },
};
