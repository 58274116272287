import * as THREE from 'three';
import imageRecordHintPoint from 'asset/image/RecordHintPoint.png';
import { loadTexture } from '../../loaders/texture-loader';
import RenderOrder from '../object-manager/mat-manager';

export default class RecordHintPoint {
  constructor(objManager) {
    this.objManager = objManager
    // 紀錄上一次回到topView前的站點 提示object
    this.recordHintPointRadiusSize = 0.01; // 半徑大小

    this.recordHintPoint = null;
  }

  /**
   * 初始化上一次DownView記錄點提示點
   */
  initRecordHintPoint() {
    const geometry = new THREE.CircleGeometry(
      this.recordHintPointRadiusSize,
      32
    );
    const material = new THREE.MeshBasicMaterial({
      side: THREE.DoubleSide,
      transparent: true,
      depthTest: false,
    });

    this.recordHintPoint = new THREE.Mesh(geometry, material);
    this.recordHintPoint.renderOrder = RenderOrder.hotspotRecord;
    this.recordHintPoint.frustumCulled = false;
    this.recordHintPoint.name = 'recordHintPoint';
    this.recordHintPoint.visible = false;
    this.recordHintPoint.rotation.x = (90 / 180) * Math.PI;
    this.recordHintPoint.position.y = -0.08;

    loadTexture(imageRecordHintPoint)
      .then(texture => {
        material.map = texture;
      })
      .catch(e => {
        console.error(e);
      });
  }

  /**
   * 改變DownView記錄點提示點位置
   * @param {*} hotspotObj
   */
  changeRecordHintPointPos(hotspotObj) {
    const { hotspots } = this.objManager.getAll();
    const tempWorldPosition = new THREE.Vector3();
    const { mainRoomId, hotspotId } = hotspotObj;
    hotspots[mainRoomId][hotspotId].mesh.getWorldPosition(tempWorldPosition);
    this.recordHintPoint.position.set(
      tempWorldPosition.x,
      tempWorldPosition.y,
      tempWorldPosition.z
    );
  }

  /**
   * 設置DownView記錄點提示點visible
   * @param {*} isVisible
   */
  setRecordHintPointVisible(isVisible) {
    this.recordHintPoint.visible = isVisible;
  }
}
