import * as THREE from 'three';

import store from 'store';
import {
    createviewerCameraControllers,
    viewerCameraControllers
} from './camera/cameraControls';
import mouseEvents from './mouse-events';
import MovingController from './moving-controls';
import { STATE, SynchronizeController } from './moving-controls/synchronizeController'
import { ObjectManagers } from './object-manager';
import { getCorespondHotspot } from './moving-controls/utils'

export default class ThreeRenderScene {
    constructor(canvas, isMain) {
        this.scene = new THREE.Scene();
        this.viewerCameraController =
            viewerCameraControllers[createviewerCameraControllers()];
        this.viewerCameraController.init(canvas);
        this.movingController = new MovingController(this.scene);
        this.mouseEventController = new mouseEvents(
            this.viewerCameraController,
            this.movingController,
            canvas
        );

        this.currentSceneInfo = null;
        this.index = 0;
        this.ObjectManager = null;
        this.sceneObject = null;
        this.isMain = isMain;

        this.animate = this.animate.bind(this);
        this.start = this.start.bind(this);
        this.init = this.init.bind(this);
    }

    animate() {
        requestAnimationFrame(this.animate);
        this.viewerCameraController.render(this.scene);
    }

    init() {
        this.setObjManagerAndSceneInfo(0);
        this.setMovingControls(0)
        this.setMouseEvent(0);

        this.movingController.init(
            this.viewerCameraController,
            this.mouseEventController.mouseHintController,
            this.ObjectManager
        );

        this.scene.add(this.mouseEventController.mouseHintController.mousePoint);
        this.scene.add(
            this.movingController.recordHintPointController.recordHintPoint
        );

        this.setScenes(SynchronizeController.sceneInfos[0]);

        this.rulerUnsubscribe = store.subscribe(this.updateRuler);
        this.roomTagUnsubscribe = store.subscribe(this.updateRoomTag);
    }

    start(cache = null) {
        const [waitRotate, waitMove] = this.movingController.start(cache);
        this.animate();

        return [waitRotate, waitMove]
    }

    unMount() {
        this.rulerUnsubscribe();
        this.roomTagUnsubscribe();
    }

    setScenes(newSceneInfo) {
        if (this.currentSceneInfo !== null) {
            this.scene.remove(this.currentSceneInfo.allRoomMesh);
            this.scene.remove(this.currentSceneInfo.doorBoundingGroup);
            this.scene.remove(this.currentSceneInfo.wallBoundingGroup);
            this.scene.remove(this.currentSceneInfo.floorplanAreaGroup);
            this.scene.remove(this.currentSceneInfo.rulerGroup);
        }
        this.currentSceneInfo = newSceneInfo;

        this.scene.add(this.currentSceneInfo.allRoomMesh);
        this.scene.add(this.currentSceneInfo.doorBoundingGroup);
        this.scene.add(this.currentSceneInfo.wallBoundingGroup);
        this.scene.add(this.currentSceneInfo.floorplanAreaGroup);
        this.scene.add(this.currentSceneInfo.rulerGroup);
    }

    setObjManagerAndSceneInfo(curindex) {
        this.index = curindex;
        this.ObjectManager = ObjectManagers[curindex];
        this.sceneObject = SynchronizeController.sceneInfos[curindex];
    }

    setMovingControls(curindex) {
        const objManager = ObjectManagers[curindex];
        this.currentSceneInfo = objManager.getAll();

        let waitTextures = [];
        if (this.movingController.objManager !== null) {
            //換成新building的站點
            let destinationHotspot = null;
            if (this.movingController.destinationHotspot != null) {
                destinationHotspot = getCorespondHotspot(
                    this.movingController.destinationHotspot,
                    curindex,
                    SynchronizeController.sceneInfos
                );
            }

            let lastDownViewHotspot = null;
            if (this.movingController.lastDownViewHotspot != null) {
                lastDownViewHotspot = getCorespondHotspot(
                    this.movingController.lastDownViewHotspot,
                    curindex,
                    SynchronizeController.sceneInfos
                );
            }

            waitTextures.push(
                this.movingController.initFpMesh(
                    objManager,
                    destinationHotspot,
                    lastDownViewHotspot
                )
            );
        }

        return Promise.all(waitTextures);
    }

    setMouseEvent(curIndex) {
        const sceneInfo = SynchronizeController.sceneInfos[curIndex];

        this.mouseEventController.setHotspotDetect(sceneInfo.allHotspotsMeshArray);
        this.mouseEventController.setCanvasEvent(
            sceneInfo.allHotspotsMeshArray,
            sceneInfo.allRoomsMeshArray, [],
            sceneInfo.hotspots
        );
    }

    getCurrentPanoId() {
        let currentPanoId = '';
        const animeControl = this.movingController;

        if (animeControl.destinationHotspot) {
            currentPanoId = animeControl.destinationHotspot.mainRoomId;
        }
        return currentPanoId;
    }

    updateRuler = () => {
        const { viewButton, viewVariable } = store.getState();
        const { viewState } = viewButton;
        if (this.isMain) {
            if (viewState === STATE.FPVIEW && !viewVariable.isCameraMoving && viewVariable.isDrawingRuler) {

                let curRoomId = this.getCurrentPanoId();
                // console.log(curRoomId);
                this.currentSceneInfo.rulerGroup.children.forEach((child, index) => {
                    if (child.roomId === curRoomId) {
                        child.setVisible(true);
                    } else {
                        child.setVisible(false);
                    }
                });
                return;
            }
        }

        this.currentSceneInfo.rulerGroup.children.forEach((child, index) => {
            child.setVisible(false);
        });
    }

    updateRoomTag = () => {
        const { viewButton } = store.getState();
        const { viewState } = viewButton;
        const { rooms } = this.currentSceneInfo
        Object.keys(rooms).forEach(roomId => {
            rooms[roomId].tagVisible = (viewState === STATE.TOPVIEW)
        })
    }

    changeStyle(index) {
        const waitTextures = this.setMovingControls(index)
        this.setScenes(SynchronizeController.sceneInfos[index])
        this.setObjManagerAndSceneInfo(index)
        this.setMouseEvent(index)

        return waitTextures
    }

    canvasResize() {
        this.viewerCameraController.windowResized();
    }

    changeFloorID(id) {
        // TODO : multi floor spec
    }
}