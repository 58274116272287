import React from 'react';
import ThreeRenderScene from 'services/3dviewer';
import { CanvasCSS, VIEW } from '../config';

export default class ThreeScene extends React.Component {
  constructor(props) {
    super(props);
    let style = CanvasCSS.whole;
    if (!this.props.isMain) {
      style = CanvasCSS.none;
    }
    this.state = {
      canvasStyle: style,
      viewChange: false,
    };

    this.threeScene = null;
  }

  render() {
    return (
      <>
        <canvas
          // style={this.state.canvasStyle}
          className={this.state.canvasStyle}
          ref={ref => {
            this.canvas = ref;
          }}
        ></canvas>
      </>
    );
  }
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
   };
  

  componentDidMount() {
    this.threeScene = new ThreeRenderScene(this.canvas, this.props.isMain);
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount(){
    this.threeScene.unMount()
  }

  componentDidUpdate() {
    let newStyle = this.updateCanvasStyle();
    if (newStyle !== this.state.canvasStyle) {
      this.setState({
        canvasStyle: newStyle,
        viewChange: true,
      });
    }

    if (this.state.viewChange) {
      this.threeScene.canvasResize();
      this.setState({
        viewChange: false,
      });
    }
  }

  updateCanvasStyle() {
    let newStyle = null;
    switch (this.props.mode) {
      case VIEW.SINGLE:
        if (this.props.isMain) {
          newStyle = CanvasCSS.whole;
        } else {
          newStyle = CanvasCSS.none;
        }
        // if (this.props.isMain) {
        //   newStyle = CanvasCSS.left;
        // } else {
        //   newStyle = CanvasCSS.right;
        // }
        break;
      case VIEW.DOUBLE:
        if (this.props.isMain) {
          newStyle = CanvasCSS.left;
        } else {
          newStyle = CanvasCSS.right;
        }
        break;
      default:
    }

    return newStyle;
  }
}
