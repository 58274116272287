import React, { Component } from 'react';
import { Trans } from 'react-i18next';

import StoreGetters from 'store/store-getters';

import cross from 'asset/image/cross.svg';
import defaultImage from 'asset/image/style_defaultimage.svg';

export default class StyleSelectBoard extends Component {
  constructor(props) {
    super(props);
  }

  loadImage(url, callback) {
    var img = new Image();
    img.src = url;
    img.onload = callback;
  }

  renderStyleButton() {
    const floorStyle = StoreGetters.getCurrentRenderFloorStyles();
    let btns = [];
    floorStyle.forEach(async (b, index) => {
      if (index > 0) {
        const { name, imageUrl } = b;

        let imageRef = null;
        btns.push(
          <button
            className="select-style-button"
            onClick={() => this.props.parent.changeSecondStyle(index)}
            key={index}
          >
            <img
              ref={ref => (imageRef = ref)}
              src={defaultImage}
              alt={`style${index}`}
            />
            <h3 className="select-style-button-name">{name}</h3>
          </button>
        );

        if (imageUrl != null) {
          this.loadImage(imageUrl, () => {
            if (imageRef) {
              imageRef.src = imageUrl;
            }
          });
        }
      }
    });
    return btns;
  }

  closeStyleSelectBoard = () => {
    this.props.parent.triggerStyleSelectWindow();
  };

  componentDidMount() {
    let div = document.getElementsByClassName('select-style-button-list')[0];
    div.addEventListener('wheel', this.onMouseWheel);
  }

  onMouseWheel = (event) => {
    event.stopPropagation();
  }

  render() {
    const floorStyle = StoreGetters.getCurrentRenderFloorStyles();

    return (
      <div className="select-style">
        <div className="select-style-title">
          <Trans>ActionSelectStyle</Trans>
          <img
            className="close"
            src={cross}
            onClick={this.closeStyleSelectBoard}
            alt="close"
          />
        </div>
        <ul className="select-style-button-list">
          {Object.keys(floorStyle).map((b, index) => {
            
            if (index > 0) {
              const { name, imageUrl } = floorStyle[b];
              return(
                <button
                  className="select-style-button"
                  onClick={() => this.props.parent.changeSecondStyle(index)}
                  key={index}
                >
                  <img
                    // ref={ref => (imageRef = ref)}
                    src={imageUrl ? imageUrl : defaultImage}
                    alt={`style${index}`}
                  />
                  <h3 className="select-style-button-name">{name}</h3>
                </button>
              )
            } else {
              return ''
            }
          })}
        </ul>
      </div>
    );
  }
}
