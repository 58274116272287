import React, { Component } from 'react';
import store from 'store';
import './css/ruler.scss';

export default class Ruler extends Component {
  constructor() {
    super();
    this.state = {
      bottom: '50%',
      right: '50%',
      display: '',
      RWD: 'PC',
    };

    this.handleRWD = this.handleRWD.bind(this);
  }

  updateRulerTag = () => {
    const { mesh, scene, scale, offset } = this.props;
    const { viewerCameraController } = scene;
    let currentPanoId = this.getCurrentPanoId();

    const { viewVariable } = store.getState();

    const sreenCoordinate = mesh.getScreenPosition(viewerCameraController);

    if (
      currentPanoId === mesh.roomId &&
      sreenCoordinate.x > 0 &&
      sreenCoordinate.x < 1 &&
      sreenCoordinate.y > 0 &&
      sreenCoordinate.y < 1 &&
      sreenCoordinate.z < 1.0 &&
      sreenCoordinate.z > -1.0 &&
      viewVariable.isDrawingRuler &&
      !viewVariable.isCameraMoving
    ) {
      if (this.state.RWD == 'Mobile') {
        const screenX = (1 - sreenCoordinate.x) * 100;
        const screenY = (1 - sreenCoordinate.y) * scale + offset;
        this.updatePosition(screenX, screenY);
      } else {
        const screenX = (1 - sreenCoordinate.x) * scale + offset;
        const screenY = (1 - sreenCoordinate.y) * 100;
        this.updatePosition(screenX, screenY);
      }
    } else {
      this.hide();
    }
  };

  getCurrentPanoId() {
    let currentPanoId = '';
    const { scene } = this.props;
    const { movingController } = scene;
    if (movingController.destinationHotspot) {
      currentPanoId = movingController.destinationHotspot.mainRoomId;
    }
    return currentPanoId;
  }

  handleRWD() {
    if (window.innerWidth < 1366) {
      this.setState({
        RWD: 'Mobile',
      });
    } else {
      this.setState({
        RWD: 'PC',
      });
    }
  }

  componentDidMount() {
    // const { mesh, scene } = this.props;
    const { mesh } = this.props;
    this.length = (mesh.length * 160).toFixed(2).toString() + 'cm';
    mesh.onAfterRender = this.updateRulerTag;
    this.hide();

    this.handleRWD();
    window.addEventListener('resize', this.handleRWD);

    this.unsubscribe = store.subscribe(this.updateRulerTag);
  }

  componentWillUnmount() {
    this.unsubscribe();
    const { mesh } = this.props;
    mesh.onAfterRender = () => {};
    mesh.setVisible(false);
  }

  updatePosition = (left, top) => {
    this.setState({
      right: `${left.toString()}%`,
      bottom: `${top.toString()}%`,
      display: '',
    });
  };

  hide() {
    if (this.state.display != 'none') {
      this.setState({
        display: 'none',
      });
    }
  }

  render() {
    return (
      <div className="ruler" style={this.state}>
        {this.length}
      </div>
    );
  }
}
