import DoorCarrier from './door';
import HotspotCarrier from './hotspot';
import RoomCarrier from './room';
import TextureTransitionCarrier from './TextureTransitionCarrier';

export default {
  DoorCarrier,
  HotspotCarrier,
  RoomCarrier,
  TextureTransitionCarrier,
};
