import * as THREE from 'three';
import imageCameraIcon from 'asset/image/floorplan_camera.png';
import { loadTexture } from '../../../loaders/texture-loader';
// camera
const geometry = new THREE.PlaneGeometry(0.15, 0.15, 1);
const material = new THREE.MeshBasicMaterial({
  map: null,
  transparent: true,
  side: THREE.DoubleSide,
  opacity: 1,
  depthTest: false,
});
loadTexture(imageCameraIcon)
  .then(texture => {
    material.map = texture;
  })
  .catch(e => {
    console.error(e);
  });
const cameraIcon = new THREE.Mesh(geometry, material);
const cameraMesh = new THREE.Group();

// init camera icon style
cameraMesh.visible = false;
cameraIcon.rotateX(Math.PI / 2);
cameraIcon.rotateZ(Math.PI);
cameraIcon.translateY(0.03);

cameraMesh.add(cameraIcon);
cameraMesh.renderOrder = 10;

export { cameraMesh, cameraIcon };
