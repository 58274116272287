const RenderOrder = {
    tagGroup: 20,
    fpMesh: 2,

    fadeOut: 10,

    room: 15,
    hotspot: 16,
    door: 17,
    ruler: 18,


    hotspotRecord: 25,
    mouseHint: 26,

    floorPlanPlane: 49,
    floorPlanDoor: 50,
    floorPlanRoomLine: 51,

};

export { RenderOrder as default };