import React, { Component } from 'react';

export default class MultiFloorDropdown extends Component {
    constructor(props) {
        super(props);
        let options = this.props.options;
        // options.push({value: -1, label: "全部"})
        this.state = {
            RWD: this.props.RWD,
            options: options,
            currentFloor: this.props.currentFloor,
            parent: this.props.parent,
            showLength: 5,
        }
    }

    handleClick = (value) => {
        let currentFloor = this.state.currentFloor;
        if(value === "up")
        {
            if(currentFloor < this.state.options.length-2)
            {
                this.setState({
                    currentFloor: currentFloor + 1
                })
                this.state.parent.changeFloor(currentFloor + 1);
            }
        }
        else if(value === "down")
        {
            if(currentFloor > 0)
            {
                this.setState({
                    currentFloor: currentFloor - 1
                })
                this.state.parent.changeFloor(currentFloor - 1);
            }
        }
        else
        {
            if(value !== currentFloor && value !== -1)
            {
                this.setState({
                    currentFloor: value
                })
                this.state.parent.changeFloor(value);
            }
        }
    }

    render() {
        let optionLength = Math.min(this.state.options.length, this.state.showLength);
        if(optionLength === 0)
            return null;
        
        let listSize = [120, 600];
        let buttonUnselectSize = [85, 55];
        let buttonSelectSize = [100, 65];
        let buttonInterval = 40;

        if(this.state.RWD == 'Mobile')
        {
            listSize = [63, 280];
            buttonUnselectSize = [40, 26];
            buttonSelectSize = [47, 30];
            buttonInterval = 15;
        }
        else
        {
            
        }

        return (
            <div style={{
                display: "flex",
                alignItems: "center",
                height: "100vh",
            }}>
                <div className="multifloor-dropdown" style={{
                    width: listSize[0],
                    height: listSize[1],
                }}>
                    <button 
                        style={{
                            width: listSize[0],
                            height: buttonUnselectSize[1],
                            background: "rgba(0, 0, 0, 0)",
                            border: 0,
                            color: "white",
                        }}
                        onClick = {(event) => {this.handleClick("up"); event.stopPropagation();}}
                    >
                        <i className="multifloor-button-up"></i>
                    </button>

                    {this.state.options.map((e, key) => {
                        let crntIndex = this.state.currentFloor;
                        if(Math.abs(key - crntIndex) <= 2)
                        {
                            let offset = (crntIndex - key) * (buttonUnselectSize[1] + buttonInterval) + listSize[1] / 2 - buttonSelectSize[1] / 2;
                            let buttonClass = (key-crntIndex == 0) ? "multifloor-button-select" : "multifloor-button";
                            let buttonSize = (key-crntIndex == 0) ? buttonSelectSize : buttonUnselectSize;
                            return <button key={e.value} 
                                        className={buttonClass} 
                                        style={{
                                            top: offset+'px',
                                            width: buttonSize[0]+'px',
                                            height: buttonSize[1]+'px',
                                        }}
                                        onClick = {(event) => {this.handleClick(e.value); event.stopPropagation();}}
                                    >
                                        {e.label}
                                    </button>
                        }
                    })}

                    <button 
                        style={{
                            width: listSize[0],
                            height: buttonUnselectSize[1],
                            background: "rgba(0, 0, 0, 0)",
                            border: 0,
                            color: "white",
                        }}
                        onClick = {(event) => {this.handleClick("down"); event.stopPropagation();}}
                    >
                        <i className="multifloor-button-down"></i>
                    </button>
                </div>
            </div>
        )
    }
}