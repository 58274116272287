import React, { Component } from 'react';
import store from 'store';
import actions from 'store/actions';
import StoreGetters from 'store/store-getters';
import { SynchronizeController } from 'services/3dviewer/moving-controls/synchronizeController';
import {
  createObjectManager,
  ObjectManagers,
} from 'services/3dviewer/object-manager';

import ThreeScene from './threeScene';
import StyleSelectBoard from './styleSelectBoard';
import SelectionList from './selectionList';
import ToolBar from './toolbar';
import QRCodeBoard from './QRCodeBoard';
import ViewChangeMenu from './viewChangeMenu';
import RoomTag from './roomtag';
import Ruler from './ruler';
import VerifySection from './verifySection';

import { VIEW } from './config';
import Waterprint from 'asset/image/waterprint.png';
import cross from 'asset/image/cross.svg';
import './css/viewer.scss';

export default class Viewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewLoadCompleted: false,
      view: VIEW.SINGLE,
      openQRCodeBoard: false,
      openStyleBoard: false,
    };

    this.rulerStatus = false;
    console.log("version : 20210708/ea31d1f6")
  }

  async loadData(callback) {
    const {
      dollHouseCubemapsData,
      hierarchy,
      labels,
      doorModelData,
      roomModelData,
    } = StoreGetters.getCurrentRenderFloorData();

    ObjectManagers.length = 0;
    for (let i = 0; i < hierarchy.length; i++) {
      const ObjectManager = ObjectManagers[createObjectManager()];
      SynchronizeController.setSceneInfo(
        await ObjectManager.init(
          hierarchy[i].panoramas,
          roomModelData,
          doorModelData,
          labels,
          dollHouseCubemapsData
        )
      );
    }
    const { building } = store.getState();
    this.selectionList.init(building);
    console.log(building);
    callback();
  }

  async componentDidMount() {
    SynchronizeController.setRenderScenes(
      this.leftScene.threeScene,
      this.rightScene.threeScene
    );
    this.loadData(() => {
      SynchronizeController.init(() => {
        this.props.onReady();
        this.setState({ viewLoadCompleted: true });
      }, this.props.cache);
    });

    let viewVariable = {
      isDrawingRuler: false,
      isCameraMoving: false,
    };

    store.dispatch(actions.setViewVariable(viewVariable));
  }

  changeSecondStyle = index => {
    const { styles } = StoreGetters.getCurrentRenderFloorData();
    if (styles.length === 1) {
      alert('資料只有一個style');
      return;
    }

    const wait = SynchronizeController.changeStyle(index);
    // eslint-disable-next-line
    if (wait != undefined) {
      wait.then(() => {
        this.changeView(VIEW.DOUBLE);
      });
    }
  };

  changeView = view => {
    this.setState({
      view: view,
      openStyleBoard: false
    });
  };

  triggerQRCodeBoard() {
    this.setState({
      openQRCodeBoard: !this.state.openQRCodeBoard,
    });
  }

  triggerStyleSelectWindow() {
    this.setState({
      openStyleBoard: !this.state.openStyleBoard,
    });
  }

  changeFloor(value) {
    this.props.changeFloor(value);
  }

  render() {
    const { data } = StoreGetters.getCurrentRenderFloorData();
    const { viewLoadCompleted } = this.state;
    let queryStr = store.getState().queryStringList

    const roomTagsLeft = [];
    const roomTagsRight = [];
    const wallRulerleft = [];

    if (viewLoadCompleted) {
      let leftscale = 50;
      let leftoffset = 50;
      if (this.state.view === VIEW.SINGLE) {
        leftscale = 100;
        leftoffset = 0;
      }

      if (this.leftScene) {
        // room tag
        const leftObj = this.leftScene.threeScene.ObjectManager;
        const Obj_leftInfo = leftObj.getAll();
        const roomNameLeft = Object.keys(Obj_leftInfo.rooms);
        roomNameLeft.forEach((id, index) => {
          const group = data.group[index];
          roomTagsLeft.push(
            <RoomTag
              room={Obj_leftInfo.rooms[id]}
              key={id}
              scale={leftscale}
              offset={leftoffset}
              scene={this.leftScene.threeScene}
              isShowing={group.info.isShowing}
            />
          );
        });

        // ruler
        const rulerMeshes = Obj_leftInfo.rulerGroup.children;
        rulerMeshes.forEach((mesh, index) => {
          wallRulerleft.push(
            <Ruler
              mesh={mesh}
              key={index}
              scale={leftscale}
              offset={leftoffset}
              scene={this.leftScene.threeScene}
            />
          );
        });
      }

      if (this.rightScene && this.state.view === VIEW.DOUBLE) {
        // room tag
        const rightObj = this.rightScene.threeScene.ObjectManager;
        const Obj_rightInfo = rightObj.getAll();
        const roomNameRight = Object.keys(Obj_rightInfo.rooms);

        roomNameRight.forEach((id, index) => {
          const group = data.group[index];
          roomTagsRight.push(
            <RoomTag
              room={Obj_rightInfo.rooms[id]}
              key={id}
              scale={50}
              offset={0}
              scene={this.rightScene.threeScene}
              isShowing={group.info.isShowing}
            />
          );
        });
      }
    }

    return (
      <div className="viewerContainer">
        <ThreeScene
          isMain={true}
          mode={this.state.view}
          ref={ref => {
            this.leftScene = ref;
          }}
        />
        <ThreeScene
          isMain={false}
          mode={this.state.view}
          ref={ref => {
            this.rightScene = ref;
          }}
        />
        <SelectionList
          ref={ref => {
            this.selectionList = ref;
          }}
          currentFloor={this.props.currentFloor}
          currentFloorIdx={this.props.currentFloorIdx}
          parent={this}
        />

        <VerifySection />

        {
          queryStr["hq"] === "yc" &&  <div className="waterprint">
          <img src={Waterprint} alt="waterprint" />
        </div>
        }
       
        {viewLoadCompleted && wallRulerleft}
        {viewLoadCompleted && roomTagsLeft}
        {viewLoadCompleted && roomTagsRight}
        {this.state.view === VIEW.DOUBLE && (
          <div className="styleboardclose">
            <img
              className="closeBtn"
              src={cross}
              onClick={() => this.changeView(VIEW.SINGLE)}
              alt="closeBtn"
            />
          </div>
        )}
        {this.state.openStyleBoard && (
          <StyleSelectBoard mode={this.state.view} parent={this} />
        )}
        {viewLoadCompleted && (
          <ViewChangeMenu
            viewerCameraController={
              this.leftScene.threeScene.viewerCameraController
            }
            parent={this}
          />
        )}
        <ToolBar
          mode={this.state.view}
          parent={this}
          viewerCameraController={
            this.leftScene != null
              ? this.leftScene.threeScene.viewerCameraController
              : null
          }
        />
        {this.state.openQRCodeBoard === true && <QRCodeBoard parent={this} />}
      </div>
    );
  }
}
