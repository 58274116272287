import React from 'react';
import PropTypes from 'prop-types';
import store from 'store';
import './css/progressbar.scss';

export default class ProgressBar extends React.Component {
  get percent() {
    const { value, max } = this.props;
    const newValue = parseInt(value, 10);
    const newMax = parseInt(max, 10);
    return Math.round((100 * newValue) / newMax) / 100;
  }

  render() {
    const progressBarTransform = {
      transform: `scaleX(${this.percent})`,
    };
    
    let queryStr = store.getState().queryStringList
    return (
      <div className="progress">
        <span className={queryStr["hq"] === "yc" ? "progress-inner-yc":"progress-inner"} style={progressBarTransform} />
      </div>
    );
  }
}
ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};
