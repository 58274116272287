import anime from 'animejs/lib/anime.es';
import * as THREE from 'three';

function fovEasing(t) {
  return t;
  // return t;
}
function posEasing(t) {
  const time = t - 1;
  return time * time * time + 1;
  //   return 1 - --t * t * t * t;
}

const duration = 1500;

const clip = (cameraControls, dstFovDegree, ObjectManager) => {
  ObjectManager.setAllHotspotOpacity(0);
  ObjectManager.setAllMeshOpacity(1);

  cameraControls.setGoinglimit(true);

  const srcPosition = cameraControls.getCamerPosition();
  const dstPosition = new THREE.Vector3(0, 1, 0);

  const srcPolarAngle = cameraControls.polarAngle;
  const dstPolarAngle = 0;

  const srcAziAngle = cameraControls.azimuthAngle;
  const dstAziAngle = 0;

  const srcFov = cameraControls.fov;
  const dstFov = (dstFovDegree / 180) * Math.PI;

  // 控anime.js開始動畫
  const timeline = anime.timeline({
    autoplay: false,
    duration,
  });

  timeline.add(
    {
      update(anim) {
        const fovPercentage = fovEasing(anim.progress / 100);
        const posEasingPercentage = posEasing(anim.progress / 100);

        // fix fov position;
        const dir = new THREE.Vector3().lerpVectors(
          srcPosition,
          dstPosition,
          posEasingPercentage
        );

        // transition fov position;
        const calFov = (1 - fovPercentage) * srcFov + fovPercentage * dstFov;

        const deserveScreenHeight =
          Math.tan(((75 / 180) * Math.PI) / 2) * dir.length() * 2;
        const distance = (1 / Math.tan(calFov / 2)) * (deserveScreenHeight / 2);

        const position = dir
          .normalize()
          .clone()
          .multiplyScalar(distance);

        const camControl = cameraControls;
        camControl.fov = calFov;
        cameraControls.setCamerPos(position, true);
      },
    },
    0
  );

  timeline.add(
    {
      targets: cameraControls,
      polarAngle: [srcPolarAngle, dstPolarAngle],
      easing: 'easeOutExpo',
    },
    0
  );

  timeline.add(
    {
      targets: cameraControls,
      azimuthAngle: [srcAziAngle, dstAziAngle],
      easing: 'easeOutExpo',
    },
    0
  );

  timeline.play();

  timeline.finished.then(() => {
    const lookAtPoint = cameraControls.getLookAtCenter().clone();
    lookAtPoint.y = 0;
    cameraControls.setLookAtCenter(lookAtPoint);

    cameraControls.setOrthographicLimit(lookAtPoint);
  });

  return timeline.finished;
};

export default clip;
